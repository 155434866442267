import { IAdHocProject, IHatchetAiCompanyProjectOcr, IProject } from "../../models/applicationState";
import { AnyAction } from "../actions/actionCreators";
import { ActionTypes } from "../actions/actionTypes";


export const reducer = (state: IAdHocProject = null, action: AnyAction): IAdHocProject => {    
    switch(action.type) {
        case ActionTypes.AD_HOC_OCR_LOAD_PROJECT: {
            console.log("reducer: " + action.payload);
            let project: IProject = JSON.parse(action.payload.project);
            let ocrs = [...action.payload.ocrs];            
            // let ocrs: IHatchetAiCompanyProjectOcr[] = [];
            // if (action.payload.ocrs && action.payload.ocrs.length > 0) {
            //     ocrs.push({...action.payload.ocrs[0]});
            //     ocrs[0].
            // }
            let assetUrls: string[] = action.payload.assetUrls;
            return { 
                ...state,
                project: project,
                ocrs: ocrs, 
                assetUrls: assetUrls
            };
            break;
        }
        case ActionTypes.AD_HOC_OCR_UPDATE_PROJECT_OCR: {
            return {
                ...state, 
                ocrs: action.payload
            }
            break;
        }
        default: {
            return state;
        }
    }
}