import React from "react";
import {
    Modal, mergeStyleSets, getTheme, FontWeights, IIconProps, TextField, Dialog, DialogContent, DialogFooter, DialogType, IContextualMenuProps, IContextualMenuItem, Label, DefaultPalette, Link, Checkbox, IContextualMenuItemProps, Spinner, SpinnerSize
}
from "@fluentui/react";
import { DefaultButton, PrimaryButton, IButtonStyles, IconButton } from '@fluentui/react/lib/Button';
import { Stack, IStackProps, IStackStyles } from '@fluentui/react/lib/Stack';
import { Dropdown, DropdownMenuItemType, IDropdownOption, IDropdownStyles } from '@fluentui/react/lib/Dropdown';

import RequestHeaders from './requestHeaders';
import { IHatchetAiCompanyAPIConnection, IApplicationState, IProject, IBodyData, IAuthData, IValidationError } from "../../../../models/applicationState";
import { connect } from "react-redux";
import { getCookieEmail, parseEndpointByType } from "../../common/jsonSchemaFormHelper";
import axios from 'axios';
import AuthorizationSettingsTab from './authorizationSettingsTab';
import HeaderSettingsTab from "./headerSettingsTab";
import BodySettingsTab from "./bodySettingsTab";
import ResultSettingsTab from "./resultSettingsTab";
import { update } from "../../../../redux/actions/prebuiltSettingsActions";

export interface IUploadGeneratedTagsProps {
    showUploadTagsModal: boolean,
    hideUploadGeneratedTagsToApi: () => void,
    project?: IProject,
    uploadTagsModalKey: number
}

export interface IUploadGeneratedTagsState {
    apiConnections: IHatchetAiCompanyAPIConnection[],
    apiConnectionOptions: IDropdownOption[],
    activeSettingsTab: string,
    saveModalFlag: boolean,
    selectedAPIConnection: IHatchetAiCompanyAPIConnection,
    updateFlag: number,
    saveAsNew: boolean,
    selectedAPIConnectionCleanState: string,
    confirmCloseFlag: boolean,
    confirmDeleteFlag: boolean,
    validationError: IValidationError,
    responseText: string,
    responseStatus: string,
    uploadingToApi: boolean
}

interface IToken {
    accessToken: string,
    tokenType: string
}

function mapStateToProps(state: IApplicationState) {
    return {
        appSettings: state.appSettings,
        project: state.currentProject,
        connections: state.connections,
        recentProjects: state.recentProjects,
    };
}


@connect(mapStateToProps, null)
export default class UploadGeneratedTagsDialog extends React.Component<IUploadGeneratedTagsProps, IUploadGeneratedTagsState> {
    theme = getTheme();
    confirmCloseContentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            minWidth: '200px',
            width: '400px'
        },
        header: [
            this.theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${this.theme.palette.themePrimary}`,
                color: this.theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        heading: {
            color: this.theme.palette.neutralPrimary,
            fontWeight: FontWeights.semibold,
            fontSize: 'inherit',
            margin: '0',
        },
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        }
    });
    saveModalContentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            minWidth: '200px',
            width: '500px'
        },
        header: [
            this.theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${this.theme.palette.themePrimary}`,
                color: this.theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        heading: {
            color: this.theme.palette.neutralPrimary,
            fontWeight: FontWeights.semibold,
            fontSize: 'inherit',
            margin: '0',
        },
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        }
    });
    contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            minWidth: '300px',
            width: '700px',
            minHeight: '670px'
        },
        header: [
            this.theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                borderTop: `4px solid ${this.theme.palette.themePrimary}`,
                color: this.theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        heading: {
            color: this.theme.palette.neutralPrimary,
            fontWeight: FontWeights.semibold,
            fontSize: 'inherit',
            margin: '0',
        },
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });
    iconButtonStyles: Partial<IButtonStyles> = {
        root: {
            color: this.theme.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: this.theme.palette.neutralDark,
        },
    };
    cancelIcon: IIconProps = { iconName: 'Cancel' };
    stackStyles: Partial<IStackStyles> = { root: { width: 630 } };
    columnProps: Partial<IStackProps> = {
        tokens: { childrenGap: 15 },
        styles: { root: { width: 630 } },
    };
    
    itemStyles: React.CSSProperties = {
        alignItems: 'center',
        background: DefaultPalette.themePrimary,
        color: DefaultPalette.white,
        display: 'flex',
        height: 50,
        justifyContent: 'center',
        width: 50
    };

    authSettingsTabRef = null;
    requestHeadersTabRef = null;
    bodyTabRef = null;

    emptyCredential = {
        id: 0,
        name: '',
        method: 'POST',
        endpointUrl: '',
        authType: 0,
        authEndpointUrl: '',
        credentialsJson: '',
        headersJson: '',
        bodyType: 'raw',
        bodyTextType: 'JSON',
        bodyText: '',
        isPublic: false
    } as IHatchetAiCompanyAPIConnection;

    saveItem = {
        key: 'save',
        text: 'Save',
        iconProps: { iconName: 'Save' },
        onClick: (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) => {
            this.toggleSaveModal(true, false, null);
        }
    }
    saveItemAs = {
        key: 'saveas',
        text: 'Save As',
        iconProps: { iconName: 'SaveAs' },
        onClick: (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) => {
            this.toggleSaveModal(true, true, null);
        }
    }

    saveMenuProps = (): IContextualMenuProps => {
        let menuItems = []
        menuItems.push(this.saveItem);
        if (this.state.selectedAPIConnection.id > 0) {
            menuItems.push(this.saveItemAs);
        }
        return { items: menuItems } as IContextualMenuProps;
    }

    constructor(props: Readonly<IUploadGeneratedTagsProps>) {
        super(props);
        this.state = {
            apiConnections: [],
            apiConnectionOptions: [{ key: 0, text: 'New API Connection'}],
            activeSettingsTab: 'Authorization',
            saveModalFlag: false,
            selectedAPIConnection: this.emptyCredential,
            updateFlag: 0,
            saveAsNew: false,
            selectedAPIConnectionCleanState: JSON.stringify(this.emptyCredential),
            confirmCloseFlag: false,
            confirmDeleteFlag: false,
            validationError: {},
            responseText: '',
            responseStatus: '',
            uploadingToApi: false
        }

        this.onEndpointChanged = this.onEndpointChanged.bind(this);
        this.uploadToEndpoint = this.uploadToEndpoint.bind(this);
        this.acquireAccessToken = this.acquireAccessToken.bind(this);
        this.toggleSaveModal = this.toggleSaveModal.bind(this);
        this.populateConnection = this.populateConnection.bind(this);
        this.onNameChanged = this.onNameChanged.bind(this);
        this.onIsPublicChanged = this.onIsPublicChanged.bind(this);
        this.saveConnection = this.saveConnection.bind(this);
        this.getAPIConnections = this.getAPIConnections.bind(this);
        this.onAPIConnectionDropdownChanged = this.onAPIConnectionDropdownChanged.bind(this);
        this.hideUploadGeneratedTagsToApi = this.hideUploadGeneratedTagsToApi.bind(this);
        this.toggleConfirmCloseModal = this.toggleConfirmCloseModal.bind(this);
        this.hideConfirmCloseModal = this.hideConfirmCloseModal.bind(this);
        this.deleteSelectedConnection = this.deleteSelectedConnection.bind(this);
        this.toggleConfirmDeleteModal = this.toggleConfirmDeleteModal.bind(this);
        this.onFileUploadEndpointChanged = this.onFileUploadEndpointChanged.bind(this);        
    }

    componentDidMount() {
        this.getAPIConnections();
    }

    componentDidUpdate(prevProps: Readonly<IUploadGeneratedTagsProps>) {
        if (prevProps.uploadTagsModalKey !== this.props.uploadTagsModalKey) {
            this.setState({
                activeSettingsTab: 'Authorization',
                saveModalFlag: false,
                selectedAPIConnection: this.emptyCredential,
                updateFlag: 0,
                saveAsNew: false,
                selectedAPIConnectionCleanState: JSON.stringify(this.emptyCredential),
                confirmCloseFlag: false
            });
        }
    }

    private async getAPIConnections() {
        const email = getCookieEmail();
        const res = await axios.post(parseEndpointByType("GetAPIConnections"), { email });
        if (res.data.successful) {            
            if (res.data) {
                const apiConnections = res.data.hatchetAiCompanyUploadEndpoints as IHatchetAiCompanyAPIConnection[];
                let apiConnectionOptions = [];
                if (apiConnections && apiConnections.length > 0) {
                    apiConnectionOptions.push({ key: 0, text: 'New API Connection' });
                    apiConnections.forEach(r => {
                        apiConnectionOptions.push({
                            key: r.id,
                            id: r.id.toString(),
                            text: r.name
                        } as IDropdownOption);
                    });
                    this.setState({
                        apiConnections, 
                        apiConnectionOptions
                    });
                }
            }
        }
    }

    private populateConnection = (): IHatchetAiCompanyAPIConnection => {
        const bodyData = this.bodyTabRef.getBodyData() as IBodyData;
        const authData = this.authSettingsTabRef.getAuthData() as IAuthData;
        const headersJson = this.requestHeadersTabRef.getRequestHeaderData() as string;

        const { selectedAPIConnection } = this.state;
        let updateModel: IHatchetAiCompanyAPIConnection = {
            id: selectedAPIConnection.id,
            name: selectedAPIConnection.name,
            method: selectedAPIConnection.method,
            endpointUrl: selectedAPIConnection.endpointUrl,
            authType: authData.authType,
            authEndpointUrl: authData.authEndpointUrl,
            credentialsJson: authData.credentialsJson,
            headersJson: headersJson,
            bodyText: bodyData.bodyText,
            bodyTextType: bodyData.bodyTextType,
            bodyType: bodyData.bodyType,
            isPublic: selectedAPIConnection.isPublic,
            fileUploadEndpoint: selectedAPIConnection.fileUploadEndpoint
        };

        return updateModel;
    }

    private async saveConnection(closeWindowAfterSaving: boolean) {
        let { saveAsNew, selectedAPIConnection, apiConnections, apiConnectionOptions, validationError } = this.state;
        let isNew = selectedAPIConnection.id < 1;
        if (!isNew) {
            isNew = saveAsNew;
        }
        let endpoint = this.populateConnection();
        validationError = {};
        if (!endpoint.name) {
            validationError['name'] = ['Name is required.'];
        }
        if (!endpoint.endpointUrl) {
            validationError['endpointUrl'] = ['Endpoint URL is required.'];
        }
        if (!endpoint.fileUploadEndpoint) {
            validationError['fileUploadEndpoint'] = ['File Upload Endpoint URL is required.'];
        }
        if (!endpoint.authEndpointUrl && endpoint.authType > 0) {
            validationError['authEndpointUrl'] = ['Authentication URL is required'];
        }
        if (!endpoint.bodyText.includes(process.env.REACT_APP_UPLOAD_TO_API_DATA_VAR)) {
            validationError['bodyText'] = [`${process.env.REACT_APP_UPLOAD_TO_API_DATA_VAR} must be part of the body.`];
        }
        if (Object.keys(validationError).length > 0) {
            this.setState({
                validationError
            });
            return;
        }
        if (isNew) {
            endpoint.id = 0;
        }
        let data = {
            hatchetAiCompanyAPIConnection: endpoint,
            email: getCookieEmail(),
            isNew
        }
        const res = await axios.post(parseEndpointByType("SaveHatchetAiCompanyAPIConnection"), data);
        if (res.data.successful) {            
            if (isNew) {
                const savedConnection = res.data.hatchetAiCompanyAPIConnection as IHatchetAiCompanyAPIConnection;
                apiConnections.push(savedConnection);
                apiConnectionOptions.push({ key: savedConnection.id, id: savedConnection.id.toString(), text: savedConnection.name } as IDropdownOption);
                selectedAPIConnection = savedConnection;
            }
            this.setState({
                apiConnections,
                apiConnectionOptions,
                selectedAPIConnection,
                selectedAPIConnectionCleanState: JSON.stringify(selectedAPIConnection),
                confirmCloseFlag: false,
                validationError: {}
            }, () => {
                this.toggleSaveModal(false, false, () => {
                    if (closeWindowAfterSaving) {
                        this.props.hideUploadGeneratedTagsToApi();
                    }
                });            
            });            
        }
    }

    private acquireAccessToken = async (): Promise<IToken> => {
        console.log('acquireAccessToken');
        const authEndpointUrl = this.authSettingsTabRef.getAuthenticationEndpoint();
        const credentialsJsonString = this.authSettingsTabRef.getCredentialsJson();
        try {
            const resp = await axios.post(parseEndpointByType("AcquireAccessToken"), { tokenUrl: authEndpointUrl, tokenBody: credentialsJsonString });
            if (resp.data) {
                return {
                    accessToken: resp.data.access_token,
                    tokenType: resp.data.token_type
                }
            }
            console.log('resp', resp);
        }
        catch (e) {
            console.log(e);
        }
        return null;
    }

    private async uploadToEndpoint() {
        //var accessToken = await this.acquireAccessToken();
        //start working on uploading the tagged to optimusapi
        this.setState({
            uploadingToApi: true
        }, async () => {
            const assets = this.props.project.assets;

            let arrays = Array<string>();
            let keys = Object.keys(assets);

            for (let index = 0; index < keys.length; index++) {
                const key = keys[index];
                arrays.push(assets[key].name);
            }

            let updateModel = this.populateConnection();

            const data = {
                hatchetAiCompanyUploadEndpoint: updateModel,
                projectName: this.props.project.name,
                fileNames: arrays,
                email: getCookieEmail()
            }
            const res = await axios.post(parseEndpointByType("UploadTagsToEndpoint"), data);
            const self = this;
            if (res.data && res.data.successful) {
                this.setState({
                    responseStatus: res.data.stringValue2,
                    responseText: res.data.stringValue1,
                    activeSettingsTab: 'Result',
                    uploadingToApi: false
                });
            }
            else {
                console.log(res.data);
                this.setState({
                    activeSettingsTab: 'Result',
                    uploadingToApi: false
                })
            }
        });        
    }

    private onEndpointChanged = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        let { selectedAPIConnection } = this.state;
        selectedAPIConnection.endpointUrl = newValue;
        this.setState({
            selectedAPIConnection
        });
    }

    private onFileUploadEndpointChanged = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        let { selectedAPIConnection } = this.state;
        selectedAPIConnection.fileUploadEndpoint = newValue;
        this.setState({
            selectedAPIConnection
        });
    }

    private onNameChanged = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        let { selectedAPIConnection } = this.state;
        selectedAPIConnection.name = newValue;
        this.setState({
            selectedAPIConnection
        });
    }

    private onIsPublicChanged = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
        let { selectedAPIConnection } = this.state;
        selectedAPIConnection.isPublic = checked as boolean;
        this.setState({
            selectedAPIConnection
        });
    }

    private onSettingsTabClicked = (tab: string) => {
        this.setState({
            activeSettingsTab: tab
        });
    }

    private toggleSaveModal = (show: boolean, isNew: boolean, callback: () => void) => {
        this.setState({
            saveModalFlag: show,
            saveAsNew: isNew
        }, () => {
            if (callback) {
                callback();
            }
        })
    }

    private onAPIConnectionDropdownChanged = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        const { apiConnections } = this.state;
        const selectedKey = item.key as number;
        if (selectedKey > 0) {
            let selectedAPIConnection = apiConnections.find(r => r.id === selectedKey);
            if (selectedAPIConnection) {
                this.setState({
                    selectedAPIConnection,
                    updateFlag: new Date().getTime(),
                    selectedAPIConnectionCleanState: JSON.stringify(selectedAPIConnection)
                });                
            }
        }
        else {
            this.setState({
                selectedAPIConnection: this.emptyCredential,
                updateFlag: new Date().getTime(),
                selectedAPIConnectionCleanState: JSON.stringify(this.emptyCredential)
            })
        }
    }

    private hideUploadGeneratedTagsToApi() {
        const { selectedAPIConnection, selectedAPIConnectionCleanState } = this.state;
        if (JSON.stringify(selectedAPIConnection) !== selectedAPIConnectionCleanState) {
            this.toggleConfirmCloseModal(true);
        }
        else {
            this.props.hideUploadGeneratedTagsToApi();
        }        
    }

    private forceHideWindow() {
        this.props.hideUploadGeneratedTagsToApi();
    }

    private toggleConfirmCloseModal(show: boolean) {        
        this.setState({
            confirmCloseFlag: show
        });
    }

    private hideConfirmCloseModal() {
        this.setState({
            confirmCloseFlag: false,
        }, () => {
            this.props.hideUploadGeneratedTagsToApi();
        })
    }

    private async deleteSelectedConnection() {
        let { selectedAPIConnection, apiConnections, apiConnectionOptions } = this.state;
        const id = selectedAPIConnection.id;
        const result = await axios.post(parseEndpointByType('DeleteAPIConnection'), { id: id });
        if (result.data.successful) {
            apiConnections = apiConnections.filter(r => r.id !== id);
            apiConnectionOptions = apiConnectionOptions.filter(r => r.key !== id);
            this.setState({
                activeSettingsTab: 'Authorization',
                saveModalFlag: false,
                selectedAPIConnection: this.emptyCredential,
                updateFlag: 0,
                saveAsNew: false,
                selectedAPIConnectionCleanState: JSON.stringify(this.emptyCredential),
                confirmCloseFlag: false,
                apiConnections,
                apiConnectionOptions,
                confirmDeleteFlag: false
            });
        }
    }

    private toggleConfirmDeleteModal(show: boolean) {
        this.setState({
            confirmDeleteFlag: show
        });
    }

    public render() {
        const { apiConnectionOptions, activeSettingsTab, saveModalFlag, selectedAPIConnection, updateFlag, confirmCloseFlag, confirmDeleteFlag, validationError, responseText, responseStatus, uploadingToApi } = this.state;

        const nameErrors = !!validationError['name'] ? validationError['name'] : null;
        let nameError = '';
        if (nameErrors) {
            nameError = nameErrors.join('. ');
        }
        const endpointErrors = !!validationError['endpointUrl'] ? validationError['endpointUrl'] : null;
        let endpointError = '';
        if (endpointErrors) {
            endpointError = endpointErrors.join('. ');
        }
        const fileUploadEndpointErrors = !!validationError['fileUploadEndpoint'] ? validationError['fileUploadEndpoint'] : null;
        let fileUploadEndpointError = '';
        if (fileUploadEndpointErrors) {
            fileUploadEndpointError = fileUploadEndpointErrors.join('. ');
        }

        return (
            <React.Fragment>
                <Modal
                    isOpen={this.props.showUploadTagsModal}
                    isBlocking={true}
                    onDismiss={this.hideUploadGeneratedTagsToApi}
                    containerClassName={this.contentStyles.container}
                >
                    <div className={this.contentStyles.header}>
                        <h2 className={this.contentStyles.heading}>
                            Upload to API
                        </h2>
                        <IconButton
                            styles={this.iconButtonStyles}
                            iconProps={this.cancelIcon}
                            ariaLabel="Close popup modal"
                            onClick={this.hideUploadGeneratedTagsToApi}                        
                        />
                    </div>
                    <div className={this.contentStyles.body}>
                        <Stack
                            tokens={{
                                childrenGap: 10,
                                padding: 10,
                            }}
                            style={{minHeight: "550px"} }
                        >
                            <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="end">
                                <Stack.Item align="stretch" grow={6} >
                                    <Dropdown
                                        label="Saved Settings"
                                        selectedKey={selectedAPIConnection.id}
                                        options={apiConnectionOptions}
                                        placeholder="Select saved credentials"
                                        onChange={this.onAPIConnectionDropdownChanged}
                                    />
                                </Stack.Item>
                                {selectedAPIConnection.id > 0 &&
                                    <IconButton
                                        iconProps={{ iconName: 'Delete' }}
                                        splitButtonAriaLabel="See 2 options"
                                        aria-roledescription="split button"
                                        ariaLabel="Delete Selected Connection"
                                        title={`Delete ${selectedAPIConnection.name} connection`}
                                        onClick={() => {this.toggleConfirmDeleteModal(true)} }
                                    />
                                }                                
                            </Stack>                            
                            <Stack.Item align="stretch">
                                <TextField label="API Endpoint" required value={selectedAPIConnection.endpointUrl} onChange={this.onEndpointChanged} errorMessage={endpointError} />
                            </Stack.Item>
                            <Stack.Item align="stretch">
                                <TextField label="File Upload Endpoint" required value={selectedAPIConnection.fileUploadEndpoint} onChange={this.onFileUploadEndpointChanged} errorMessage={fileUploadEndpointError} />
                            </Stack.Item>
                            <Stack.Item align="stretch">
                                <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 20 }}>
                                    <span><Link disabled={activeSettingsTab === 'Authorization'} onClick={() => this.onSettingsTabClicked('Authorization')}>Authorization</Link></span>
                                    <span><Link disabled={activeSettingsTab === 'Headers'} onClick={() => this.onSettingsTabClicked('Headers')}>Headers</Link></span>
                                    <span><Link disabled={activeSettingsTab === 'Body'} onClick={() => this.onSettingsTabClicked('Body')}>Body</Link></span>
                                    <span><Link disabled={activeSettingsTab === 'Result'} onClick={() => this.onSettingsTabClicked('Result')}>Result</Link></span>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item align="stretch">
                                {
                                    <AuthorizationSettingsTab
                                        authType={selectedAPIConnection.authType}
                                        credentialsJson={selectedAPIConnection.credentialsJson}
                                        authEndpointUrl={selectedAPIConnection.authEndpointUrl}
                                        activeSettingsTab={activeSettingsTab}
                                        updateFlag={updateFlag}
                                        validationError={validationError}
                                        ref={(e) => (this.authSettingsTabRef = e) }
                                    />                            
                                }
                                {
                                    <HeaderSettingsTab
                                        headersJson={selectedAPIConnection.headersJson}
                                        activeSettingsTab={activeSettingsTab}
                                        updateFlag={updateFlag}
                                        ref={(e) => (this.requestHeadersTabRef = e)}
                                    />
                                }
                                {
                                    <BodySettingsTab
                                        bodyText={selectedAPIConnection.bodyText}
                                        bodyTextType={selectedAPIConnection.bodyTextType}
                                        bodyType={selectedAPIConnection.bodyType}
                                        activeSettingsTab={activeSettingsTab}
                                        updateFlag={updateFlag}
                                        validationError={validationError}
                                        ref={(e) => (this.bodyTabRef = e)}
                                    />
                                }
                                {
                                    <ResultSettingsTab
                                        responseText={responseText}
                                        activeSettingsTab={activeSettingsTab}
                                        responseStatus={responseStatus}
                                    />
                                }
                            </Stack.Item>
                        </Stack>                        
                
                        <Stack horizontal horizontalAlign="space-between" style={{ paddingLeft: '10px'}} verticalAlign="end">
                            <DefaultButton
                                text="Save"
                                primary
                                split
                                splitButtonAriaLabel="See 2 options"
                                aria-roledescription="split button"
                                menuProps={this.saveMenuProps()}
                            />
                            <div>
                                <DefaultButton text="Close" onClick={this.hideUploadGeneratedTagsToApi} />
                                <PrimaryButton text="Upload" onClick={this.uploadToEndpoint} disabled={selectedAPIConnection.id < 1} />
                            </div>
                        </Stack>                    
                    </div>
                </Modal>
                <Modal
                    isOpen={saveModalFlag}
                    isBlocking={true}
                    containerClassName={this.saveModalContentStyles.container}
                >
                    <div className={this.saveModalContentStyles.header}>
                        <h2 className={this.saveModalContentStyles.heading}>
                            Save Connection
                        </h2>
                        <IconButton
                            styles={this.iconButtonStyles}
                            iconProps={this.cancelIcon}
                            ariaLabel="Close popup modal"
                            onClick={() => this.toggleSaveModal(false, false, null)}
                        />
                    </div>
                    <div className={this.saveModalContentStyles.body}>
                        <Stack tokens={{
                            childrenGap: 10,
                            padding: 10,
                        }}>
                            <Stack.Item align="stretch">
                                <TextField label="Connection Name" required value={selectedAPIConnection.name} onChange={this.onNameChanged} errorMessage={nameError} />
                            </Stack.Item>
                            <Stack.Item align="stretch">
                                <Checkbox label="Public Connection" checked={selectedAPIConnection.isPublic} onChange={this.onIsPublicChanged} />
                            </Stack.Item>
                        </Stack>
                        <Stack horizontal horizontalAlign="end" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                            <div>
                                <PrimaryButton text="Save" onClick={() => this.saveConnection(false)} />
                                <DefaultButton text="Cancel" onClick={() => this.toggleSaveModal(false, false, null)} />                                
                            </div>
                        </Stack>
                    </div>
                </Modal>

                <Modal
                    isOpen={confirmCloseFlag}
                    isBlocking={true}
                    containerClassName={this.confirmCloseContentStyles.container }
                >
                    <div className={this.confirmCloseContentStyles.header}>
                        <h2 className={this.confirmCloseContentStyles.heading}>
                            Confirm
                        </h2>
                        <IconButton
                            styles={this.iconButtonStyles}
                            iconProps={this.cancelIcon}
                            ariaLabel="Close popup modal"
                            onClick={() => this.toggleConfirmCloseModal(true)}
                        />
                    </div>
                    <div className={this.confirmCloseContentStyles.body}>
                        <Stack tokens={{
                            childrenGap: 10,
                            padding: 10,
                        }}>
                            <Stack.Item align="stretch">
                                The connection has been modified, do you want to save your changes?
                            </Stack.Item>                            
                        </Stack>
                        <Stack horizontal horizontalAlign="end" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                            <div>
                                <PrimaryButton text="Save" onClick={() => this.saveConnection(true)} />
                                <DefaultButton text="Discard" onClick={() => this.hideConfirmCloseModal()} />
                                <DefaultButton text="Cancel" onClick={() => this.toggleConfirmCloseModal(false)} />
                            </div>
                        </Stack>
                    </div>
                </Modal>

                <Modal
                    isOpen={confirmDeleteFlag}
                    isBlocking={true}
                    containerClassName={this.confirmCloseContentStyles.container}
                >
                    <div className={this.confirmCloseContentStyles.header}>
                        <h2 className={this.confirmCloseContentStyles.heading}>
                            Confirm
                        </h2>
                        <IconButton
                            styles={this.iconButtonStyles}
                            iconProps={this.cancelIcon}
                            ariaLabel="Delete api modal"
                            onClick={() => this.toggleConfirmDeleteModal(true)}
                        />
                    </div>
                    <div className={this.confirmCloseContentStyles.body}>
                        <Stack tokens={{
                            childrenGap: 10,
                            padding: 10,
                        }}>
                            <Stack.Item align="stretch">
                                Are you sure you to delete the selected API connection?
                            </Stack.Item>
                        </Stack>
                        <Stack horizontal horizontalAlign="end" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                            <div>
                                <PrimaryButton text="Ok" onClick={this.deleteSelectedConnection} />
                                <DefaultButton text="Cancel" onClick={() => this.toggleConfirmDeleteModal(false)} />
                            </div>
                        </Stack>
                    </div>
                </Modal>

                { uploadingToApi &&
                    <div className="project-saving" style={{ zIndex: 1008017 }}>
                        <div className="project-saving-spinner">
                            <Label className="p-0"></Label>
                            <Spinner
                                size={SpinnerSize.large}
                                label="Uploading to API..."
                                ariaLive="assertive"
                                labelPosition="right"
                            />
                        </div>
                    </div>
                }                
            </React.Fragment>
        )
    }
}
