import {
    Customizer,
    Dialog,
    DialogContent,
    DialogType,
    ICustomizations,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Link
} from "@fluentui/react";
import axios from "axios";
import React from "react";
import Form, {
    FormValidation,
    ISubmitEvent,
    Widget,
} from "react-jsonschema-form";
import { toast } from "react-toastify";
import { addLocValues, interpolate } from "../../../../common/strings";
import {
    getDarkTheme,
    getPrimaryGreenTheme,
    getPrimaryGreyTheme
} from "../../../../common/themes";
import {
    IFormLogin,
    ILoginUserCommand,
    IProject,
} from "../../../../models/applicationState";
import CustomFieldTemplate from "../customField/customFieldTemplate";
import {
    baseUrl,
    parseEndpointByType,
    setAdminValue,
    setCookieRoleValue,
    setCookieValue,
} from "../jsonSchemaFormHelper";
import { PasswordInput } from "../passwordInput/passwordInput";

/**
 * Properties for File Picker
 * @member onChange - Function to call on change of file selection
 * @member onError - Function to call on file picking error
 */
export interface ILoginDialogProps {
    project?: IProject;
    onLoginClick: () => void;
    onBackClick: () => void;
    show: boolean;
    onLoginSuccessClick: () => void;
    onResetPasswordClick: () => void;
}

export interface ILoginDialogState {
    show: boolean;
    registrationFormSchema: any;
    registrationUIFormSchema: any;
    formData: IFormLogin;
    showSuccess: boolean;
    loading: boolean;
    errors: FormValidation;
    errorLogin: string;
    linkedIn?: any;
}

// tslint:disable-next-line:no-var-requires
const registrationFormSchema = addLocValues(require("./loginDialogForm.json"));
const registrationUIFormSchema = addLocValues(
    // tslint:disable-next-line:no-var-requires
    require("./loginDialogForm.ui.json")
);

/**
 * @name - loginDialog
 * @description - Authenticate user on first visit
 */
export default class LoginDialog extends React.Component<
    ILoginDialogProps,
    ILoginDialogState
> {
    private widgets = {
        passwordInput: PasswordInput as any as Widget,
    };

    // hachet
    constructor(props, context) {
        super(props, context);

        this.state = {
            show: false,
            registrationFormSchema: {},
            registrationUIFormSchema: {},
            formData: {} as IFormLogin,
            showSuccess: false,
            loading: false,
            errors: {} as FormValidation,
            errorLogin: "",
            linkedIn: {
                state: window.sessionStorage.getItem("linkedin_state")
                    ? window.sessionStorage.getItem("linkedin_state")
                    : null,
                redirect_uri: baseUrl(),
                profileImage: null,
            } as object,
        };

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.backClick = this.backClick.bind(this);
        this.resetClick = this.resetClick.bind(this);
    }

    componentDidMount() {
        this.setState({
            registrationFormSchema,
        });
        this.setState({
            registrationUIFormSchema,
        });
    }

    public render() {
        const dark: ICustomizations = {
            settings: {
                theme: getDarkTheme(),
            },
            scopedSettings: {},
        };

        return (
            
            <Dialog
                // onDismiss={this.onCloseClick}
                hidden={!this.props.show}
                dialogContentProps={{
                    type: DialogType.normal,

                    isMultiline: true,
                }}
                minWidth="500px"
                modalProps={{
                    isBlocking: false,
                }}
            >
                <DialogContent>
                    <div className="row p-0 m-0">
                        <div className="col-sm-5 p-0 m-0">
                            <h4
                                style={{
                                    marginTop: "-40px",
                                    marginBottom: "0",
                                }}
                            >
                                Sign In
                            </h4>
                        </div>
                        <div className="col-sm-7 p-0 m-0 text-right">
                            <img
                                alt=""
                                className="logo"
                                width="160px"
                                style={{
                                    cursor: "pointer",
                                    marginTop: "-66px",
                                }}
                                src={require("../../../../assets/images/linkedin-signin.png")}
                                onClick={() => this.loginLinkedIn()}
                            ></img>
                        </div>
                        <div className="col-sm-12 p-0 m-0">
                            <span
                                style={{
                                    marginBottom: "10px",
                                    display: "block",
                                    color: "#ec8484",
                                }}
                            >
                                {this.state.errorLogin}
                            </span>
                            <Form
                                showErrorList={false}
                                liveValidate={true}
                                noHtml5Validate={true}
                                FieldTemplate={CustomFieldTemplate}
                                validate={this.onFormValidate}
                                schema={this.state.registrationFormSchema}
                                uiSchema={
                                    this.state.registrationUIFormSchema
                                }
                                formData={this.state.formData}
                                onSubmit={this.onFormSubmit}
                                fields={this.fields()}
                                widgets={this.widgets}
                            >
                                <div className="row">
                                    <div className="col-sm-12">
                                        <PrimaryButton
                                            disabled={
                                                this.state.errors
                                                    .__errors &&
                                                this.state.errors.__errors
                                                    .length > 0
                                            }
                                            theme={getPrimaryGreenTheme()}
                                            className="mr-2"
                                            type="submit"
                                            style={{width: '100%'} }
                                        >
                                            {this.state.loading ? (
                                                <Spinner
                                                    size={SpinnerSize.small}
                                                    label="Verifying..."
                                                    ariaLive="off"
                                                    labelPosition="right"
                                                />
                                            ) : (
                                                "Login"
                                            )}
                                        </PrimaryButton>                                            
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt-2 col-sm-6">
                                        <Link
                                            disabled={
                                                this.state.errors
                                                    .__errors &&
                                                this.state.errors.__errors
                                                    .length > 0
                                            }
                                            theme={getPrimaryGreyTheme()}
                                            className="mr-2"
                                            type="button"
                                            onClick={this.backClick}
                                        >
                                            Register
                                        </Link> | 
                                        <Link
                                            disabled={
                                                this.state.errors
                                                    .__errors &&
                                                this.state.errors.__errors
                                                    .length > 0
                                            }
                                            theme={getPrimaryGreyTheme()}
                                            className="ml-2"
                                            type="button"
                                            onClick={this.resetClick}
                                        >
                                            Reset Password
                                        </Link>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    private backClick() {
        this.setState({
            errorLogin: "",
            formData: {
                email: "",
                password: "",
            },
        });

        this.props.onBackClick();
    }

    private resetClick() {
        this.setState({
            errorLogin: "",
            formData: {
                email: "",
                password: "",
            },
        });

        this.props.onResetPasswordClick();
    }

    private async loginLinkedIn() {
        try {
            this.setState({
                loading: true,
            });

            let responseType = "code";
            let clientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID;

            let redirectUri = baseUrl("login", true);
            let scope = "r_liteprofile r_emailaddress";

            let res = await axios.get(parseEndpointByType("GenerateToken"));
            let state = res.data;

            window.sessionStorage.setItem("linkedin_state", state);
            window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=${responseType}&state=${state}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`;
        } catch (error) {
            console.log(error.response);
        } finally {
            this.setState({
                loading: false,
            });
        }
    }

    private onFormValidate = (
        registration: IFormLogin,
        errors: FormValidation
    ) => {
        return errors;
    };

    private onFormSubmit(args: ISubmitEvent<IFormLogin>) {
        try {
            this.setState({
                loading: true,
                formData: args.formData,
            });

            axios
                .post(parseEndpointByType("Login"), {
                    email: args.formData.email,
                    password: args.formData.password,
                } as ILoginUserCommand)
                .then((response: any) => {
                    if (response.data.successful) {
                        if (response.data.booleanValue1) {
                            setAdminValue();
                        }

                        setCookieValue(response.data.stringValue1);
                        if (response.data.stringValue2) {
                            setCookieRoleValue(response.data.stringValue2);
                        }                        
                        this.props.onLoginSuccessClick();
                    } else {
                        this.setState({
                            errorLogin: response.data.errorMessage,
                        });

                        toast.error(
                            interpolate(response.data.errorMessage, {})
                        );
                    }
                })
                .catch((error) => {
                    this.setState({
                        errorLogin: error.response,
                    });
                    toast.error(interpolate(error.response, {}));
                })
                .finally(() => {
                    this.setState({
                        loading: false,
                    });
                });
        } catch (error) {
            console.log(error);
        }
    }

    private fields() {
        return {};
    }
}
