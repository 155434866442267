import React, { useEffect } from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

export interface ISignalRComponentProps {
    uploadMessage: (signalR: any) => void;
}

const SignalRComponent = (signalRProps: ISignalRComponentProps) => {
    useEffect(() => {
        const hatchetAppHub = process.env.REACT_APP_HACHET_API_URL + 'fileuploadhub';

        const connection = new HubConnectionBuilder()
            .withUrl(hatchetAppHub)
            .configureLogging(LogLevel.Information)
            .build();

        function startConnection() {
            connection.start()
                .then(() => {
                    console.log('SignalR connection started');
                })
                .catch((error) => {
                    console.error('Error starting SignalR connection:', error);
                    setTimeout(startConnection, 5000); // Retry connection after 5 seconds
                });
        }

        // Handle disconnection
        connection.onclose((error) => {
            console.log('SignalR connection closed:', error);
            setTimeout(startConnection, 5000); // Retry connection after 5 seconds
        });

        // Define your hub methods and event handlers here
        connection.on('ReceiveMessage', signalR => {
            signalRProps.uploadMessage(signalR)
        });

        // Start the connection
        startConnection();

        // Cleanup the connection when the component unmounts
        return () => {
            connection.stop();
        };
    }, []);

    return <div></div>;
};

export default SignalRComponent;
