import {
    Customizer,
    DefaultButton,
    Dialog,
    DialogContent,
    DialogType,
    ICustomizations,
    Label,
    PrimaryButton,
    Spinner,
    SpinnerSize,
} from "@fluentui/react";
import axios from "axios";
import React from "react";
import Form, { FormValidation, ISubmitEvent } from "react-jsonschema-form";
import { addLocValues } from "../../../../common/strings";
import {
    getDarkTheme,
    getPrimaryGreenTheme,
    getPrimaryGreyTheme,
} from "../../../../common/themes";
import {
    ICompany,
    IFormRegistration,
    IProject,
    IRegisterUserCommand,
} from "../../../../models/applicationState";
import CustomFieldTemplate from "../customField/customFieldTemplate";
import { baseUrl, parseEndpointByType } from "../jsonSchemaFormHelper";
import Success from "../success/success";
import { toast, ToastContainer } from "react-toastify";


/**
 * Properties for File Picker
 * @member onChange - Function to call on change of file selection
 * @member onError - Function to call on file picking error
 */
export interface IRegisterDialogProps {
    project?: IProject;
    onLoginClick: () => void;
    showRegisterDialog: boolean;
    onSuccessRegistrationClick: () => void;
    onLoginSuccessClick: () => void;
    data?: IFormRegistration;
    backClick: () => void;
    companies: ICompany[];
}

export interface IRegisterDialogState {
    show: boolean;
    registrationFormSchema: any;
    registrationUIFormSchema: any;
    formData: IFormRegistration;
    showSuccess: boolean;
    saving: boolean;
    errors: FormValidation;
    linkedIn: any;
    isfromExternalLogin: boolean;
    loading: boolean;
    domain?: string;
}

// tslint:disable-next-line:no-var-requires
const registrationFormSchema = addLocValues(
    // tslint:disable-next-line:no-var-requires
    require("./registerDialogForm.json")
);
const registrationUIFormSchema = addLocValues(
    // tslint:disable-next-line:no-var-requires
    require("./registerDialogForm.ui.json")
);

/**
 * @name - loginDialog
 * @description - Authenticate user on first visit
 */
export default class RegisterDialog extends React.Component<
    IRegisterDialogProps,
    IRegisterDialogState
> {
    // hachet
    constructor(props, context) {
        super(props, context);

        this.state = {
            show: false,
            registrationFormSchema: {},
            registrationUIFormSchema: {},
            formData: {} as IFormRegistration,
            showSuccess: false,
            saving: false,
            errors: {} as FormValidation,
            linkedIn: {
                state: window.sessionStorage.getItem("linkedin_state")
                    ? window.sessionStorage.getItem("linkedin_state")
                    : null,
                redirect_uri: baseUrl(),
                profileImage: null,
            } as object,
            isfromExternalLogin: false,
            loading: false,
        };

        this.onCloseClick = this.onCloseClick.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onCloseSuccessClick = this.onCloseSuccessClick.bind(this);
        this.showLoginClick = this.showLoginClick.bind(this);
    }

    componentDidMount() {
        this.setState({
            registrationFormSchema,
        });
        this.setState({
            registrationUIFormSchema,
            formData: this.props.data,
        });
    }

    componentWillReceiveProps() {
        this.setState({
            formData: this.props.data,
        });
    }

    public render() {
        const dynamicRegistrationSchema = {
            ...registrationFormSchema,
            properties: {
                ...registrationFormSchema.properties,
                company: {
                    ...registrationFormSchema.properties.company,
                    enum: this.props.companies.map(option => option.id),
                    enumNames: this.props.companies.map(option => option.name),
                },
            },
        };

        const dark: ICustomizations = {
            settings: {
                theme: getDarkTheme(),
            },
            scopedSettings: {},
        };

        return (
            <React.Fragment>
                <Success
                    title="Registration Successful"
                    message="Thank you for registering to Hatchet Ai. A link has been sent to your email for verification."
                    show={this.state.showSuccess}
                    onClose={this.onCloseSuccessClick}
                    showClose={false}
                />

                <Dialog
                    // onDismiss={this.onCloseClick}
                    hidden={!this.props.showRegisterDialog}
                    dialogContentProps={{
                        type: DialogType.normal,

                        isMultiline: true,
                    }}
                    minWidth="700px"
                    modalProps={{
                        isBlocking: false,
                    }}
                >
                    <DialogContent>
                        {this.state.loading && (
                            <div className="project-saving small">
                                <div className="project-saving-spinner">
                                    <Label className="p-0"></Label>
                                    <Spinner
                                        size={SpinnerSize.large}
                                        label="Loading..."
                                        ariaLive="assertive"
                                        labelPosition="right"
                                    />
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-sm-12">
                                <h3
                                    style={{
                                        marginTop: "-40px",
                                        marginBottom: "30px",
                                    }}
                                >
                                    Thanks for your interest in Hatchet!
                                </h3>
                                <h5 style={{ marginBottom: "30px" }}>
                                    To start your trial of our artificial
                                    intelligence data capture tool, please enter
                                    your work info. A verification link will be
                                    sent to your email!
                                </h5>
                            </div>
                            <div className="col-sm-8">
                                <h5 style={{ marginBottom: "30px" }}>
                                    If you already have an account please{" "}
                                    <a
                                        href="#"
                                        style={{ color: "#3c9ef4" }}
                                        onClick={() => this.showLoginClick()}
                                    >
                                        Sign In
                                    </a>
                                </h5>
                            </div>
                            <div className="col-sm-12 text-center">
                                <img
                                    onClick={() => this.loginLinkedIn()}
                                    alt=""
                                    className="logo"
                                    style={{ cursor: "pointer" }}
                                    width="160px"
                                    src={require("../../../../assets/images/linkedin-signin.png")}
                                ></img>
                            </div>
                        </div>
                        <Form
                            showErrorList={false}
                            liveValidate={true}
                            noHtml5Validate={true}
                            FieldTemplate={CustomFieldTemplate}
                            validate={this.onFormValidate}
                            schema={dynamicRegistrationSchema}
                            uiSchema={this.state.registrationUIFormSchema}
                            formData={this.state.formData}
                            onChange={this.onFormChange}
                            onSubmit={this.onFormSubmit}
                            fields={this.fields()} autocomplete="false"
                        >
                            <div className="row">
                                <div className="col-sm-6">
                                    <PrimaryButton
                                        disabled={
                                            this.state.errors.__errors &&
                                            this.state.errors.__errors.length >
                                                0
                                        }
                                        theme={getPrimaryGreenTheme()}
                                        className="mr-2"
                                        type="submit"
                                    >
                                        {this.state.saving ? (
                                            <Spinner
                                                size={SpinnerSize.small}
                                                label="Saving..."
                                                ariaLive="off"
                                                labelPosition="right"
                                            />
                                        ) : (
                                            "Register"
                                        )}
                                    </PrimaryButton>
                                    <PrimaryButton
                                        disabled={
                                            this.state.errors.__errors &&
                                            this.state.errors.__errors.length >
                                                0
                                        }
                                        theme={getPrimaryGreyTheme()}
                                        className="mr-2"
                                        type="button"
                                        onClick={this.props.backClick}
                                    >
                                        Back
                                    </PrimaryButton>
                                </div>
                                {/* <div className="col-sm-6 text-right">
                                    <DefaultButton>
                                        Add Company
                                    </DefaultButton>
                                </div> */}
                            </div>
                        </Form>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }

    private showLoginClick() {
        this.props.onLoginClick();
    }

    private onCloseClick() {
        this.setState({
            show: false,
        });
    }

    private async loginLinkedIn() {
        try {
            this.setState({
                loading: true,
            });

            let responseType = "code";
            let clientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID;

            let redirectUri = baseUrl("register", true);
            let scope = "r_liteprofile r_emailaddress";

            let res = await axios.get(parseEndpointByType("GenerateToken"));
            let state = res.data;

            window.sessionStorage.setItem("linkedin_state", state);
            window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=${responseType}&state=${state}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`;
        } catch (error) {
            console.log(error.response);
        } finally {
            this.setState({
                loading: false,
            });
        }
    }

    private onCloseSuccessClick() {
        this.setState({
            showSuccess: false,
        });
    }

    private onFormValidate = (
        registration: IFormRegistration,
        errors: FormValidation
    ) => {
        return errors;
    };

    private onFormChange() {
        console.log("");
    }

    private onFormSubmit(args: ISubmitEvent<IFormRegistration>) {
        try {
            this.setState({
                saving: true,
                formData: args.formData,
            }, () => {
                axios
                    .post(parseEndpointByType("RegisterUser"), {
                        form: args.formData,
                    } as IRegisterUserCommand)
                    .then((response: any) => {
                        if (response.data.successful) {
                            this.setState({
                                showSuccess: true,
                            });

                            this.props.onSuccessRegistrationClick();
                        }
                    })
                    .catch((error) => console.log(error))
                    .finally(() => {
                        this.setState({
                            saving: false,
                        });
                    });
            });
        } catch (error) {
            console.log(error);
        }
    }

    private fields() {
        return {};
    }
}
