import React from "react";
import {
    IHatchetAiCompanyAPIConnection
} from "../../../../models/applicationState";
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from '@fluentui/react/lib/DetailsList';
import { TextField } from '@fluentui/react/lib/TextField';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { IconButton, ActionButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Stack } from "@fluentui/react";
import "./uploadEndpointsPage.scss";
import NewEndpointModal from './newEndpointModal';
import axios from "axios";
import {
    getCookieAdmin,
    getCookieEmail,
    parseEndpointByType,
} from "../../common/jsonSchemaFormHelper";

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px',
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden',
            },
        },
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '16px',
        maxWidth: '16px',
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px',
    },
    selectionDetails: {
        marginBottom: '20px',
    },
});
const controlStyles = {
    root: {
        margin: '0 30px 20px 0',
        maxWidth: '300px',
    },
};

export interface IUploadEndpointsPageProps extends React.Props<UploaadEndpointsPage> {

}

export interface IUploadEndpointsPageState {
    hatchetUploadEndpoints: IHatchetAiCompanyAPIConnection[],
    filterText?: string,
    showEditModal: boolean,
    updateEditFlag: number,
    currentModel: IHatchetAiCompanyAPIConnection
}

export default class UploaadEndpointsPage extends React.Component<IUploadEndpointsPageProps, IUploadEndpointsPageState> {
    constructor(props) {
        super(props);

        this.state = {
            hatchetUploadEndpoints: [],
            filterText: null,
            showEditModal: false,
            updateEditFlag: 0,
            currentModel: {
                id: 0,
                name: '',
                method: '',
                endpointUrl: 'POST',
                authEndpointUrl: '',
                authType: 0,
                credentialsJson: '',
                headersJson: '',
                bodyText: '',
                bodyTextType: '',
                bodyType: '',
                isPublic: false,
                fileUploadEndpoint: ''
            }
        }

        this.showUploadModal = this.showUploadModal.bind(this);
        this.closeUploadModal = this.closeUploadModal.bind(this);

        //this._selection = new Selection({
        //    onSelectionChanged: () => {
        //        this.setState({
        //            selectionDetails: this._getSelectionDetails(),
        //        });
        //    },
        //});
    }

    async componentDidMount() {
        await this.getUploadEndpoints();        
    }

    private async getUploadEndpoints() {
        const email = getCookieEmail();
        if (email) {
            const res = await axios.get(parseEndpointByType("GetUploadEndpoints"),
                {
                    params: {
                        email
                    },
                }
            );
            console.log('res', res);
        }
    }

    columns: IColumn[] = [
        {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            onRender: (item: IHatchetAiCompanyAPIConnection) => {
                return <span>{item.name}</span>;
            },
            isPadded: true,
        },
        {
            key: 'method',
            name: 'Method',
            fieldName: 'method',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            data: 'string',
            onRender: (item: IHatchetAiCompanyAPIConnection) => {
                return <span>{item.method}</span>;
            },
            isPadded: true,
        },
        {
            key: 'endpointUrl',
            name: 'Endpoint URL',
            fieldName: 'endpointUrl',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            onRender: (item: IHatchetAiCompanyAPIConnection) => {
                return <span>{item.endpointUrl}</span>;
            },
            isPadded: true,
        },
        {
            key: 'actions',
            name: '',            
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            data: 'number',
            onRender: (item: IHatchetAiCompanyAPIConnection) => {
                return <span>
                    <ActionButton iconProps={{ iconName: 'Edit' }} allowDisabledFocus onClick={this.showUploadModal}>
                        Edit
                    </ActionButton>
                    <ActionButton iconProps={{ iconName: 'Delete' }} allowDisabledFocus>
                        Delete
                    </ActionButton>
                </span>;
            },
            isPadded: true
        }
    ];

    private _getKey(item: IHatchetAiCompanyAPIConnection, index?: number): string {
        return item.id.toString();
    }

    private _onChangeFilterText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
        this.setState({
            filterText: text
        });
    };

    private showUploadModal = (): void => {
        this.setState({
            showEditModal: true
        });
    }

    private closeUploadModal = (): void => {
        this.setState({
            showEditModal: false
        });
    }

    private updateEndpoint = async (endpoint: IHatchetAiCompanyAPIConnection): Promise<void> => {
        const res = await axios.post(parseEndpointByType("SaveUploadEndpoint"),
            {
                hatchetAiCompanyUploadEndpoint: {
                    id: endpoint.id,
                    name: endpoint.name,
                    method: endpoint.method,
                    endpointUrl: endpoint.endpointUrl,
                    authType: endpoint.authType,
                    credentialsJson: endpoint.credentialsJson
                } as IHatchetAiCompanyAPIConnection
            } 
        );
        console.log('res', res);
    }

    private newCurrentModel = () => {
        this.setState({
            currentModel: {
                id: 0,
                name: '',
                method: 'POST',
                endpointUrl: '',
                authType: 0,
                authEndpointUrl: '',
                credentialsJson: '',
                headersJson: '',
                bodyText: '',
                bodyTextType: '',
                bodyType: '',
                isPublic: false,
                fileUploadEndpoint: ''
            },
            updateEditFlag: new Date().getTime(),
            showEditModal: true
        });
    }

    render() {
        const { hatchetUploadEndpoints, filterText, showEditModal, currentModel, updateEditFlag } = this.state;
        return (
            <div className="uploadEndpoints-page">
                <Stack>
                    <Stack horizontal horizontalAlign="space-between" verticalAlign="center" verticalFill wrap>
                        <h3>
                            Company Endpoints
                        </h3>
                        <PrimaryButton text="New" onClick={this.newCurrentModel} />
                    </Stack>
                    <Stack horizontal horizontalAlign="start" verticalAlign="center" verticalFill wrap>
                        <TextField label="Filter by name:" onChange={this._onChangeFilterText} styles={controlStyles} value={filterText} />
                        <IconButton iconProps={{ iconName: 'Search' }} title="Search" ariaLabel="Search" />
                    </Stack>                    
                    <DetailsList
                        items={hatchetUploadEndpoints}
                        compact={false}
                        columns={this.columns}
                        getKey={this._getKey}
                        setKey="multiple"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        selectionPreservedOnEmptyClick={true}
                        enterModalSelectionOnTouch={true}
                        ariaLabelForSelectionColumn="Toggle selection"
                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        checkButtonAriaLabel="select row"
                        />
                </Stack>
                <NewEndpointModal
                    showEditModal={showEditModal}
                    closeUploadModal={this.closeUploadModal}
                    updateEndpoint={this.updateEndpoint}
                    model={currentModel}
                    updateEditFlag={updateEditFlag}
                />
            </div>
        );
    }
}
