import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import React, { SyntheticEvent } from "react";
import { IFileInfo, IProject } from "../../../../models/applicationState";
import XHRUpload from "@uppy/xhr-upload";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/webcam/dist/style.css";

import { getCookieEmail, parseEndpointByType } from "../jsonSchemaFormHelper";
import Webcam from "@uppy/webcam";
import axios from "axios";


/**
 * Properties for File Picker
 * @member onChange - Function to call on change of file selection
 * @member onError - Function to call on file picking error
 */
export interface IFileUploaderProps {
    onChange: (sender: SyntheticEvent, fileText: IFileInfo) => void;
    onError: (sender: SyntheticEvent, error: any) => void;
    onUploadComplete: (sender: SyntheticEvent, array: string[]) => void;
    uploadType: string;
    project?: IProject;
    sessionId: string;
    onDialogClosed: () => void;
    splitMultiplePageFiles?: boolean;
}

export interface IFileUploaderState {
    totalFilesToUpload: number;
}

/**
 * @name - File Picker
 * @description - Pick file from local file system
 */
export default class FileUploader extends React.Component<
    IFileUploaderProps,
    IFileUploaderState
> {
    private uppy;
    private cookieEmail = getCookieEmail();

    // hachet
    constructor(props, context) {
        super(props, context);

        this.state = {
            totalFilesToUpload: 0,
        };

        const maxTotalSize500Mmb = 524288000000;
        const maxFileSize15mb = 15728640;

        setTimeout(() => {
            const self = this;
            this.uppy = Uppy({
                debug: false,
                restrictions: {
                    minNumberOfFiles: 1,
                    maxNumberOfFiles: 2000,
                    maxFileSize: maxFileSize15mb,
                    maxTotalFileSize: maxTotalSize500Mmb,
                    allowedFileTypes: self.getAllowedTypes()                    
                },
                autoProceed: false,
                allowMultipleUploads: false,
                onBeforeUpload: (files) => {        
                    Object.entries(files).forEach((item) => {
                        item[1].meta["totalFilesToUpload"] =
                            this.state.totalFilesToUpload;                        
                    });
                    return files;
                },
            })
                .use(Dashboard, {
                    inline: true,
                    target: ".uppy-dashboard-container",
                    //thumbnailWidth: 20,
                    replaceTargetContent: true,
                    showProgressDetails: true,
                    note: `Files up to 15 MB`,
                    height: 450,
                    width: 700,
                    browserBackButtonClose: true,
                    metaFields: [
                        { id: "name", name: "Name", placeholder: "File name" },
                    ],
                })
                .use(Webcam, {
                    target: Dashboard,
                    onBeforeSnapshot: () => Promise.resolve(),
                    countdown: false,
                    modes: [
                        // "video-audio",
                        // "video-only",
                        // "audio-only",
                        "picture",
                    ],
                    mirror: false,
                    videoConstraints: {
                        facingMode: "environment",
                        width: { min: 720, ideal: 1280, max: 1920 },
                        height: { min: 480, ideal: 800, max: 1080 },
                    },
                    showRecordingLength: false,
                    preferredVideoMimeType: null,
                    preferredImageMimeType: null,
                    locale: {},
                    companionUrl: "https://companion.uppy.io",
                })
                .use(XHRUpload, {
                    endpoint: this.parseEndpoint(),
                    metaFields: [
                        "id",
                        "name",
                        "size",
                        "extension",
                        "sas",
                        "sessionId",
                        "totalFilesToUpload",
                        "uploadedBy"
                    ],
                    limit: 10
                })
                .on("file-added", (file) => {
                    const self = this;

                    let count = self.state.totalFilesToUpload + 1;
                    self.setState({
                        totalFilesToUpload: count,
                    });
                    
                    //if (isMultiple) {
                    //    count = this.state.totalFilesToUpload + 1;
                    //    this.setState({
                    //        totalFilesToUpload: count,
                    //    });
                    //}

                    //this.uppy.setFileMeta(file.id, {
                    //    id: file.id,
                    //    size: file.size,
                    //    extension: file.extension,
                    //    sas: this.props.project.sourceConnection
                    //        .providerOptions["sas"],
                    //    sessionId: isMultiple ? this.props.sessionId : "",
                    //    totalFilesToUpload: count,
                    //    uploadedBy: this.cookieEmail
                    //});

                    //console.log(this.uppy);
                })
                .on('file-removed', (file, reason) => {
                    let count = self.state.totalFilesToUpload - 1;
                    self.setState({
                        totalFilesToUpload: count,
                    });
                })
                .on("upload", (item, response) => {
                    if (item && item.fileIDs) {
                        for (var i = 0; i < item.fileIDs.length; i++) {
                            var fileId = item.fileIDs[i];
                            const file = self.uppy.getFile(fileId)
                            self.uppy.setFileMeta(file.id, {
                                id: file.id,
                                size: file.size,
                                extension: file.extension,
                                sas: self.props.project.sourceConnection
                                    .providerOptions["sas"],
                                sessionId: "",
                                totalFilesToUpload: self.state.totalFilesToUpload,
                                uploadedBy: self.cookieEmail
                            });
                            self.uppy.setFileState(fileId, {
                                xhrUpload: {
                                    ...file.xhrUpload,
                                    endpoint: self.parseEndpoint()
                                }
                            });                            
                        }
                    }
                    
                })
                .on("upload-success", (file, response) => {
                    //console.log("upload-success", response);
                    this.onFileUploaded(response);
                })
                .on("complete", (response) => {
                    //console.log("complete", result);
                    this.props.onUploadComplete(null, []);
                })
                .on('cancel-all', () => {
                    // Handle the cancellation event
                    axios.post(parseEndpointByType("PreUploadFile"), {
                        sas: self.props.project.sourceConnection
                            .providerOptions["sas"], 
                        uploadedBy: getCookieEmail()
                    }).then(response => {
                        
                    });
                  })
                .on('error', (error) => {
                    alert(error.stack);
                });
        });
    }

    public render() {
        return <div className="uppy-dashboard-container"></div>;
    }

    private parseEndpoint() {
        if (this.props.uploadType !== "documents") {
            return parseEndpointByType("UploadTags");
        } else {
            if (this.props.splitMultiplePageFiles) {
                return parseEndpointByType("UploadMultiplePageFile");
            }
            else {
                return parseEndpointByType("UploadFile");
            }            
        }
    }

    private getAllowedTypes = () => {
        if (this.props.uploadType !== "documents") {
            return ['.xls', '.xlsx', '.csv'];
        } else {
            return ['image/*', '.jpg', '.jpeg', '.png', '.gif', '.pdf'];
        }
    }

    private parseApplicationType() {
        if (this.props.uploadType !== "documents") {
            return "";
        } else {
            return "application/pdf";
        }
    }

    private onFileUploaded = (response) => {
        console.log('onFileUploaded', response);
        if (this.props.onUploadComplete) {            
            if (this.props.uploadType === "tags") {                
                if (response.body) {
                    let params = Array<string>();
                    params = response.body.stringValueArray1;
                    this.props.onUploadComplete(null, params);
                }                
            }            
        }
    }

    private updateEndpoint() {
        console.log('update endpoint here');
    }
}
