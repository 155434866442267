import React, { RefObject, SyntheticEvent } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { bindActionCreators } from "redux";
import { EditorMode, FeatureCategory, FieldFormat, FieldType, IAdHocProject, IAppSettings, IApplicationState, IAsset, IAssetMetadata, IClearLabelDataCommand, IHatchetAiCompanyProjectOcr, ILabel, IProject, IProjectLabel, IRegion, ISize, ITableConfigItem, ITableRegion, ITableTag, ITag, TableVisualizationHint, TagInputMode } from "../../../../models/applicationState";
import IAdHocOcrActions, * as adHocActions from "../../../../redux/actions/adHocOcrActions";
import SplitPane from "react-split-pane";
import Canvas from "./canvas";
import { TagInput } from "../../common/tagInput/tagInput";
import { OcrStatus } from "../../../../services/ocrService";
import { AssetPreview } from "../../common/assetPreview/assetPreview";
import IProjectActions from "../../../../redux/actions/projectActions";
import * as projectActions from "../../../../redux/actions/projectActions";
import _ from "lodash";
import "../editorPage/editorPage.scss";
import IApplicationActions, * as applicationActions from "../../../../redux/actions/applicationActions";
import { constants } from "../../../../common/constants";
import CanvasHelpers from "../editorPage/canvasHelpers";
import Confirm from "../../common/confirm/confirm";
import clone from "rfdc";
import { getCookieEmail, parseEndpointByType } from "../../common/jsonSchemaFormHelper";
import axios from "axios";
import IAppTitleActions, * as appTitleActions from "../../../../redux/actions/appTitleActions";
import {
    getPrimaryBlueTheme,
    getPrimaryRedTheme,
} from "../../../../common/themes";
import { AssetService } from "../../../../services/assetService";
import EditLabelDialog from "../../common/editLabelDialog/editLabelDialog";

interface IIntegrationPageProps extends RouteComponentProps, React.Props<AdHocOcrPage> {
    adHocOcr: IAdHocProject;
    adHocActions: IAdHocOcrActions;
    appSettings: IAppSettings;
    actions: IProjectActions;
    applicationActions: IApplicationActions;
    appTitleActions: IAppTitleActions;
}

interface IIntegrationPageState {
    /** The selected asset for the primary editing experience */
    selectedAsset?: IAssetMetadata;
    editorMode: EditorMode;
    /** Tags locked for region labeling */
    lockedTags: string[];
    /** The currently hovered TagInputItemLabel */
    hoveredLabel: ILabel;
    isRunningOCRs?: boolean;
    tagInputMode: TagInputMode;
    highlightedTableCellRegions: ITableRegion[];
    selectedTableTagToLabel: ITableTag;
    isValid: boolean;
    assets: IAsset[];
    thumbnailSize: ISize;
    sidePanelSize: number;
    selectedRegions?: IRegion[];
    pageNumber: number;
    selectedTableTagBody: ITableRegion[][][];
    rightSplitPaneWidth?: number;
    reconfigureTableConfirm?: boolean;
    projectLabels: IProjectLabel[];
    showEditDialog?: boolean;
    labelToEdit?: ILabel;
    isProcessingLabel?:boolean;
}

function mapStateToProps(state: IApplicationState) {
    return {
        adHocOcr: state.adHocOcr,
        appSettings: state.appSettings
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(projectActions, dispatch),
        adHocActions: bindActionCreators(adHocActions, dispatch),
        applicationActions: bindActionCreators(applicationActions, dispatch),
        appTitleActions: bindActionCreators(appTitleActions, dispatch),        
    }
}

@connect(mapStateToProps, mapDispatchToProps)
export default class AdHocOcrPage extends React.Component<IIntegrationPageProps, IIntegrationPageState> {
    requestId: string;

    private canvas: RefObject<Canvas> = React.createRef();
    private tagInputRef: RefObject<TagInput>;
    public initialRightSplitPaneWidth: number;
    private renameCanceled: () => void;
    private renameTagConfirm: React.RefObject<Confirm> = React.createRef();
    private deleteTagsConfirm: React.RefObject<Confirm> = React.createRef();
    private deleteTagConfirm: React.RefObject<Confirm> = React.createRef();
    private replaceConfirmRef = React.createRef<Confirm>();
    private reconfigTableConfirm: React.RefObject<Confirm> = React.createRef();
    private confirmResetTagConfirm = React.createRef<Confirm>();
    private editLabelDialogRef: React.RefObject<EditLabelDialog> =
        React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            editorMode: EditorMode.Select,
            lockedTags: [],
            hoveredLabel: null,
            tagInputMode: TagInputMode.Basic,
            highlightedTableCellRegions: null,
            selectedTableTagToLabel: null,
            isValid: true, 
            assets: [],
            thumbnailSize: { width: 175, height: 155 },
            sidePanelSize: 248,
            pageNumber: 1,
            selectedTableTagBody: [[]],
            projectLabels: [],
            isProcessingLabel: false
        }
        this.tagInputRef = React.createRef();

        this.onTagDeleteChecked = this.onTagDeleteChecked.bind(this);
        this.downloadToExcelClick = this.downloadToExcelClick.bind(this);       
        this.processingLabel = this.processingLabel.bind(this);
    }
    public async componentDidMount() {
        let url = new URL(window.location.href);
        this.requestId = url.searchParams.get("requestId");
        if (this.requestId) {
            this.props.applicationActions.setWidgetMode();
            await this.props.adHocActions.loadAdHocOcrProject(this.requestId);
            await this.loadProjectAssets();            
            document.title = "Optimus - HatchetAI";
        }
    }

    private getOcrForPage = async (page: number): Promise<IHatchetAiCompanyProjectOcr> => {
        console.log('adhococrpage getocrforpage');
        const ocr = await this.props.adHocActions.getOcrProjectForPage(this.requestId, page, this.state.selectedAsset.asset.path, this.state.selectedAsset.asset.name);
        return ocr;
    }

    render() {
        console.log('this.props', this.props);
        const { selectedAsset } = this.state;
        
        const isBasicInputMode = this.state.tagInputMode === TagInputMode.Basic;
        this.initialRightSplitPaneWidth = isBasicInputMode ? 290 : 520;

        const labels =
            (selectedAsset &&
                selectedAsset.labelData &&
                selectedAsset.labelData.labels) ||
            [];
        const tableLabels =
            (selectedAsset &&
                selectedAsset.labelData &&
                selectedAsset.labelData.tableLabels) ||
            [];

        return (
            <React.Fragment>
                <div className="editor-page skipToMainContent" id="pageEditor">
                    {/* <SplitPane
                        split="vertical"
                        defaultSize={this.state.thumbnailSize.width}
                        size={this.state.sidePanelSize}
                        maxSize={325}
                        minSize={248}
                        paneStyle={{ display: "flex" }}
                        onChange={this.onSideBarResize}
                        onDragFinished={this.onSideBarResizeComplete}
                    >
                        <div className="editor-page-sidebar text-right" style={{ paddingTop: '2px', display: 'none' }}>

                        </div> */}
                        <div className="editor-page-content">
                            <SplitPane
                                split="vertical"
                                primary="second"
                                maxSize={isBasicInputMode ? 400 : 700}
                                minSize={this.initialRightSplitPaneWidth}
                                className={"right-vertical_splitPane"}
                                pane1Style={{ height: "100%" }}
                                pane2Style={{ height: "auto" }}
                                resizerStyle={{
                                    width: "5px",
                                    margin: "0px",
                                    border: "2px",
                                }}
                                onChange={(width) => {
                                    if (!isBasicInputMode) {
                                        //this.setState(
                                        //    {
                                        //        rightSplitPaneWidth: width > 700 ? 700 : width
                                        //    },
                                        //    () => {
                                        //        this.resizeCanvas();
                                        //    }
                                        //);
                                        this.setState(prevState => {
                                            return {
                                                ...prevState,
                                                rightSplitPaneWidth: width > 700 ? 700 : width
                                            }
                                        }, () => {
                                            this.resizeCanvas();
                                        })
                                        this.resizeCanvas();
                                    }
                                }}
                            >
                                <div className="editor-page-content-main">
                                    <div className="editor-page-content-main-body">
                                        { this.props && this.props.adHocOcr && this.props.adHocOcr.project && 
                                            this.state.selectedAsset && 
                                            <Canvas 
                                                ref={this.canvas}
                                                selectedAsset={
                                                    this.state.selectedAsset
                                                }
                                                onAssetMetadataChanged={
                                                    this.onAssetMetadataChanged
                                                }
                                                onCanvasRendered={
                                                    this.onCanvasRendered
                                                }
                                                onSelectedRegionsChanged={
                                                    this.onSelectedRegionsChanged
                                                }
                                                onRegionDoubleClick={
                                                    this.onRegionDoubleClick
                                                }
                                                onRunningOCRStatusChanged={
                                                    this.onCanvasRunningOCRStatusChanged
                                                }
                                                onRunningAutoLabelingStatusChanged={
                                                    this.onCanvasRunningAutoLabelingStatusChanged
                                                }
                                                onTagChanged={this.onTagChanged}
                                                onAssetDeleted={
                                                    this.confirmDocumentDeleted
                                                }
                                                editorMode={this.state.editorMode}
                                                project={this.props.adHocOcr.project}
                                                lockedTags={this.state.lockedTags}
                                                hoveredLabel={
                                                    this.state.hoveredLabel
                                                }
                                                setTableToView={this.setTableToView}
                                                closeTableView={this.closeTableView}
                                                runOcrForAllDocs={
                                                    this.loadOcrForNotVisited
                                                }
                                                isRunningOCRs={
                                                    this.state.isRunningOCRs
                                                }
                                                onPageLoaded={this.onPageLoaded}
                                                runAutoLabelingOnNextBatch={
                                                    this.runAutoLabelingOnNextBatch
                                                }
                                                appSettings={this.props.appSettings}
                                                handleLabelTable={
                                                    this.handleLabelTable
                                                }
                                                highlightedTableCell={
                                                    this.state
                                                        .highlightedTableCellRegions
                                                }
                                                showSearch={true}
                                                handlerSearch={this.onHandleSearch}
                                                showUploadGeneratedTagsToApi={this.showUploadGeneratedTagsToApi}     
                                                adHocOcr={this.props.adHocOcr}     
                                                getOcrForPage={this.getOcrForPage}         
                                                updateAdHocOcrProjectOcr={this.props.adHocActions.updateAdHocOcrProjectOcr}       
                                                widgetMode={this.props.appSettings.widgetMode}                  
                                            >
                                                <AssetPreview
                                                    controlsEnabled={
                                                        this.state.isValid
                                                    }
                                                    onBeforeAssetChanged={
                                                        this.onBeforeAssetSelected
                                                    }
                                                    asset={
                                                        this.state.selectedAsset
                                                            .asset
                                                    }
                                                />
                                            </Canvas>
                                        }                                    
                                    </div>
                                </div>

                                <div className="editor-page-right-sidebar">
                                    {this.state.selectedAsset &&
                                        (<TagInput
                                            selectAllForDelete={false}
                                            project={this.props.adHocOcr.project}
                                            tagsLoaded={true}
                                            tags={this.props.adHocOcr.project.tags}
                                            lockedTags={this.state.lockedTags}
                                            selectedRegions={
                                                this.state.selectedRegions
                                            }
                                            labels={labels}
                                            encoded={
                                                selectedAsset?.labelData
                                                    ?.$schema ===
                                                constants.labelsSchema
                                            }
                                            tableLabels={tableLabels}
                                            pageNumber={this.state.pageNumber}
                                            onChange={this.onTagsChanged}
                                            onLockedTagsChange={
                                                this.onLockedTagsChanged
                                            }
                                            onTagClick={this.onTagClicked}
                                            onCtrlTagClick={
                                                this.onCtrlTagClicked
                                            }
                                            onTagRename={this.confirmTagRename}
                                            onTagDeleted={
                                                this.confirmTagDeleted
                                            }
                                            onDeleteSeletedTags={
                                                this.confirmTagsDeleted
                                            }
                                            onDeleteCheckedChanged={
                                                this.onTagDeleteChecked
                                            }
                                            onLabelEnter={this.onLabelEnter}
                                            onLabelLeave={this.onLabelLeave}
                                            onTagChanged={this.onTagChanged}
                                            ref={this.tagInputRef}
                                            setTagInputMode={
                                                this.setTagInputMode
                                            }
                                            tagInputMode={
                                                this.state.tagInputMode
                                            }
                                            handleLabelTable={
                                                this.handleLabelTable
                                            }
                                            selectedTableTagToLabel={
                                                this.state
                                                    .selectedTableTagToLabel
                                            }
                                            handleTableCellClick={
                                                this.handleTableCellClick
                                            }
                                            handleTableCellMouseEnter={
                                                this.handleTableCellMouseEnter
                                            }
                                            handleTableCellMouseLeave={
                                                this.handleTableCellMouseLeave
                                            }
                                            selectedTableTagBody={
                                                this.state.selectedTableTagBody
                                            }
                                            splitPaneWidth={
                                                this.state.rightSplitPaneWidth
                                            }
                                            reconfigureTableConfirm={
                                                this.reconfigureTableConfirm
                                            }
                                            addRowToDynamicTable={
                                                this.addRowToDynamicTable
                                            }
                                            onTagDoubleClick={
                                                this.onLabelDoubleClicked
                                            }
                                            handelDownloadClick={(withTables: boolean) =>
                                                this.downloadToExcelClick(withTables)
                                            }
                                            onLabelEdit={this.onLabelEdit}
                                            onResetClick={this.onResetClick}
                                            confirmResetTag={this.confirmResetTag}
                                            toggleTagSpinner={this.toggleTagSpinner}
                                            widgetMode={this.props.appSettings.widgetMode}
                                            processingLabel={this.processingLabel}
                                        />)
                                    }
                                </div>
                            </SplitPane>

                            <Confirm
                                ref={this.confirmResetTagConfirm}
                                title="Reset"
                                // tslint:disable-next-line:max-line-length
                                message={() =>
                                    "Are you sure you want to reset the tag value?"
                                }
                                confirmButtonTheme={getPrimaryRedTheme()}
                                onConfirm={(label) => this.confirmResetTag(label)}
                                onCancel={() => this.onCancelResetTag()}
                            />

                            <EditLabelDialog
                                show={this.state.showEditDialog}
                                onClose={this.closeEditLabelDialog}
                                onSaveEditLabelClick={this.onSaveEditLabelClick}
                                ref={this.editLabelDialogRef}
                                container={this.props.adHocOcr?.project ? this.props.adHocOcr?.project : null}
                                label={this.state.labelToEdit}
                            />
                        </div>
                    {/* </SplitPane> */}
                </div>                
            </React.Fragment>
        );
    }

    private toggleTagSpinner = (show: boolean) => {
        this.setState(prevState => {
            return {
                ...prevState,
                processingUploadedTags: show
            }
        });
    };

    private processingLabel = (processing: boolean) => {
        this.setState(prevState => {
            return {
                ...prevState,
                isProcessingLabel: processing
            }
        });
    }
    
    // private isBusy = (): boolean => {
    //     return (
    //         this.state.isRunningOCRs ||
    //         this.state.isCanvasRunningOCR ||
    //         this.state.isCanvasRunningAutoLabeling
    //     );
    // };

    private onCancelResetTag() {
        // this.setState({ showSearch: true });
    }


    private async confirmResetTag(label: ILabel) {
        console.log('5018');
        const { selectedAsset } = this.state;
        if (selectedAsset && selectedAsset.labelData && selectedAsset.labelData.labels && selectedAsset.labelData.labels.length > 0) {
            selectedAsset.labelData.labels = selectedAsset.labelData.labels.filter((l) => l.label !== label.label);
        }        
        this.setState((prevState) => {
            return {
                ...prevState,
                clearingLabel: true
            }
        });

        try {
            const assetService = new AssetService(this.props.adHocOcr.project);
            // const result = await assetService.clearTag({
            //     email: getCookieEmail(),
            //     fileName: this.state.selectedAsset.asset.name,
            //     label: label.label,
            //     // tslint:disable-next-line:radix
            //     projectId: parseInt(this.props.adHocOcr.project.id),
            // } as IClearLabelDataCommand);

            // let updatedSelectedAsset = JSON.parse(JSON.stringify(selectedAsset));
            // updatedSelectedAsset.labelData.labels = updatedSelectedAsset.labelData.labels.filter(r => r.label !== label.label);

            let newMeta = await this.props.actions.saveAssetMetadataAdhoc(
                this.props.adHocOcr.project,
                selectedAsset
            );

            await this.props.adHocActions.updateAdHocOcrProjectAssetLabel(this.props.adHocOcr.project.id, this.props.adHocOcr.project.name, selectedAsset.asset.name, JSON.stringify(selectedAsset.labelData), false, '');

            //await this.props.adHocActions.updateAdHocOcrProjectAssetLabel(this.props.adHocOcr.project.id, this.props.adHocOcr.project.name, selectedAsset.asset.name, JSON.stringify(selectedAsset.labelData), true, label.label);
            await this.loadProjectAssets(true);
            await this.selectAsset(this.state.selectedAsset.asset, true);
            this.canvas.current.fullRedrawFeatures();

            // if (result.successful) {                
            //     await this.loadProjectAssets(true);
            //     await this.selectAsset(this.state.selectedAsset.asset, true);
            //     this.canvas.current.fullRedrawFeatures();
            //     // await this.loadProjectAssets();
            //     // await this.props.actions.loadProject(this.props.project);
            //     // await this.props.actions.refreshAsset(
            //     //     this.props.project,
            //     //     this.state.selectedAsset.asset.name
            //     // );
            // } else {
            //     console.log(result.errorMessage);
            // }
        } catch (error) {
            console.log(error);
        } finally {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    clearingLabel: false
                }
            });
        }
    }

    private onLabelEdit = (label: ILabel) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                showEditDialog: true,
                labelToEdit: label
            }
        });
    };

    private closeEditLabelDialog = () => {        
        this.setState((prevState) => {
            return {
                ...prevState,
                showEditDialog: false,
                labelToEdit: null
            }
        });
    };

    private onSaveEditLabelClick = async (
        event: SyntheticEvent,
        label: ILabel
    ) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                savingLabel: true
            }
        });

        this.canvas.current.applyTag(label.label, null, null, label);
        this.canvas.current.disableDrawRegionMode();

        setTimeout(() => {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    savingLabel: false
                }
            });
        }, 1000);
    };

    private onResetClick = (label: ILabel) => {
        this.confirmResetTagConfirm.current.open(label);
    };

    private downloadToExcelClick = async (withTables: boolean) => {
        // if (this.isBusy()) {
        //     return;
        // }

        const assets = this.props.adHocOcr.project.assets;

        let arrays = Array<string>();
        let keys = Object.keys(assets);

        for (let index = 0; index < keys.length; index++) {
            const key = keys[index];
            arrays.push(assets[key].name);
        }

        this.setState((prevState) => {
            return {
                ...prevState,
                downloading: true
            }
        });

        try {
            const response = await axios.post(
                parseEndpointByType("HachetDownloadExcel"),
                {
                    FileNames: arrays,
                    Name: this.props.adHocOcr.project.name,
                    Email: getCookieEmail(),
                    WithTables: withTables
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        withCredentials: "true",
                    },
                    responseType: "blob",
                }
            );

            if (response) {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    `"hachetai-result-${Math.round(+new Date() / 1000)}.xlsx`
                ); //or any other extension
                document.body.appendChild(link);
                link.click();

                this.setState((prevState) => {
                    return {
                        ...prevState,
                        downloading: false
                    }
                });
            }
        } catch (error) {
            console.log(error.response);
        }
    };

    private onLabelDoubleClicked = (label: ILabel) => {
        this.canvas.current.focusOnLabel(label);
    };

    private addRowToDynamicTable = () => {
        const selectedTableTagBody = clone()(this.state.selectedTableTagBody);
        selectedTableTagBody.push(
            Array(this.state.selectedTableTagToLabel.definition.fields.length)
        );
        this.setState((prevState) => {
            return {
                ...prevState,
                selectedTableTagBody
            }
        });
    };

    private handleTableCellMouseLeave = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                highlightedTableCellRegions: null
            }
        });
    };

    private handleTableCellMouseEnter = (regions) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                highlightedTableCellRegions: regions
            }
        });
    };

    private onTableTagClicked = (
        tag: ITag,
        rowIndex: number,
        columnIndex: number
    ): void => {
        this.setState((prevState) => {
            return {
                ...prevState,
                selectedTag: tag.name,
                lockedTags: []
            }
        },
            () => {
                this.canvas.current.applyTag(tag.name, rowIndex, columnIndex);
                this.canvas.current.disableDrawRegionMode();
            });
    };

    private handleTableCellClick = (rowIndex: number, columnIndex: number) => {
        if (
            !this.state.selectedRegions ||
            this.state.selectedRegions.length === 0
        ) {
            return;
        }

        if (
            this.state?.selectedTableTagBody?.[rowIndex]?.[columnIndex]
                ?.length > 0
        ) {
            const selectionRegionCatagory =
                this.state.selectedRegions[0].category;
            const cellCatagory =
                this.state.selectedTableTagBody[rowIndex][columnIndex][0]
                    .category;
            if (
                selectionRegionCatagory !== cellCatagory &&
                (selectionRegionCatagory === FeatureCategory.DrawnRegion ||
                    cellCatagory === FeatureCategory.DrawnRegion)
            ) {
                this.replaceConfirmRef.current.open(
                    this.state.selectedTableTagToLabel,
                    rowIndex,
                    columnIndex
                );
                return;
            }
        }

        this.onTableTagClicked(
            this.state.selectedTableTagToLabel,
            rowIndex,
            columnIndex
        );
    };

    private setTagInputMode = (
        tagInputMode: TagInputMode,
        selectedTableTagToLabel: ITableTag = this.state.selectedTableTagToLabel,
        selectedTableTagBody: ITableRegion[][][] = this.state.selectedTableTagBody
    ) => {
        // this.resizeCanvas();

        this.setState((prevState) => {
            return {
                ...prevState,
                selectedTableTagBody,
                selectedTableTagToLabel,
                tagInputMode,
            }
        },
            () => {
                this.resizeCanvas();
            });
    };

    private onLabelLeave = (label: ILabel) => {
        this.setState({ hoveredLabel: null });
    };

    private onLabelEnter = (label: ILabel) => {
        this.setState({ hoveredLabel: label });
    };

    private confirmTagsDeleted = (tagLabelMap: Map<string, ILabel[]>): void => {
        this.deleteTagsConfirm.current.open(tagLabelMap);
    };
    
    private confirmTagDeleted = (
        tagName: string,
        tagType: FieldType,
        tagFormat: FieldFormat,
        labels?: ILabel[]
    ): void => {
        this.deleteTagConfirm.current.open(tagName, tagType, tagFormat, labels);
    };

    private confirmTagRename = (
        tag: ITag,
        newTag: ITag,
        cancelCallback: () => void
    ): void => {
        this.renameCanceled = cancelCallback;
        this.renameTagConfirm.current.open(tag, newTag);
    };

    private onCtrlTagClicked = (tag: ITag): void => {
        const locked = this.state.lockedTags;
        this.setState((prevState) => {
            return {
                ...prevState,
                selectedTag: tag.name,
                lockedTags: CanvasHelpers.toggleTag(locked, tag.name)
            }
        },
            () => {
                this.canvas.current.applyTag(tag.name);
                this.canvas.current.disableDrawRegionMode();
            });
    };

    private onTagClicked = (tag: ITag): void => {
        this.setState((prevState) => {
            return {
                ...prevState,
                selectedTag: tag.name,
                lockedTags: [],
            }
        },
            () => {
                this.canvas.current.applyTag(tag.name);
                // this.canvas.current.disableDrawRegionMode();
            });
    };

    private onLockedTagsChanged = (lockedTags: string[]) => {
        this.setState(prevState => {
            return {
                ...prevState,
                lockedTags
            }
        });
    };

    private onTagsChanged = async (tags, tagChangedCallback?: any) => {
        const project = {
            ...this.props.adHocOcr.project,
            tags,
        };
        //TODO: Save Project
        //await this.props.actions.saveProject(project, true, false, this.savedToken);
        if (tagChangedCallback) {
            tagChangedCallback();
        }
    };

    private onSideBarResizeComplete = () => {
        const appSettings = {
            ...this.props.appSettings,
            thumbnailSize: this.state.thumbnailSize,
        };

        this.props.applicationActions.saveAppSettings(appSettings);
    };

    private onSideBarResize = (newWidth: number) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                thumbnailSize: {
                    width: newWidth,
                    height: newWidth / (4 / 3),
                }
            }
        });
        this.resizeCanvas();
    };

    private resizeCanvas = () => {
        if (this.canvas.current) {
            this.canvas.current.updateSize();
        }
    };

    private compareAssetLabelsWithProjectTags = (
        labels: ILabel[],
        tags: ITag[]
    ): boolean => {
        if (!labels || labels.length === 0) {
            return false;
        }
        const intersectionTags = _.intersectionWith(
            labels,
            tags,
            (l, t) => l.label === t.name
        );
        return intersectionTags?.length < labels.length;
    };

    /**
     * Raised when the selected asset has been changed.
     * This can either be a parent or child asset
     */
    private onAssetMetadataChanged = async (
        assetMetadata: IAssetMetadata
    ): Promise<void> => {
        // Comment out below code as we allow regions without tags, it would make labeler's work easier.
        assetMetadata = _.cloneDeep(assetMetadata);
        const initialState = assetMetadata.asset.state;

        const asset = { ...assetMetadata.asset };

        // Only update asset metadata if state changes or is different
        if (
            initialState !== asset.state ||
            this.state.selectedAsset !== assetMetadata
        ) {
            if (
                JSON.stringify(assetMetadata.labelData) !==
                JSON.stringify(this.state.selectedAsset.labelData)
            ) {
                await this.updatedAssetMetadata(assetMetadata);
            }

            assetMetadata.asset = asset;
            let newMeta = await this.props.actions.saveAssetMetadataAdhoc(
                this.props.adHocOcr.project,
                assetMetadata
            );

            await this.props.adHocActions.updateAdHocOcrProjectAssetLabel(this.props.adHocOcr.project.id, this.props.adHocOcr.project.name, assetMetadata.asset.name, JSON.stringify(assetMetadata.labelData), false, '');

            // Get regions from label data since meta data will not have regions when loaded.
            newMeta.regions =
            this.canvas.current?.convertLabelDataToRegions(
                newMeta.labelData
            ) || [];
            this.setState(prevState => {
                return {
                    ...prevState,
                    selectedAsset: newMeta
                }
            });
            if (
                this.compareAssetLabelsWithProjectTags(
                    assetMetadata.labelData?.labels,
                    this.props.adHocOcr.project.tags
                )
            ) {
                // await this.props.actions.updateProjectTagsFromFiles(
                //     this.props.adHocOcr.project,
                //     assetMetadata.asset.name
                // );
            }
        }
    };

    private async updatedAssetMetadata(assetMetadata: IAssetMetadata) {
        const rowDocumentCountDifference = {};
        const updatedRowLabels = {};
        const currentRowLabels = {};
        const columnDocumentCountDifference = {};
        const updatedColumnLabels = {};
        const currentColumnLabels = {};
        assetMetadata?.labelData?.tableLabels?.forEach((table) => {
            updatedRowLabels[table.tableKey] = {};
            updatedColumnLabels[table.tableKey] = {};
            table.labels.forEach((label) => {
                updatedRowLabels[table.tableKey][label.rowKey] = true;
                updatedColumnLabels[table.tableKey][label.columnKey] = true;
            });
        });

        this.state.selectedAsset?.labelData?.tableLabels?.forEach((table) => {
            currentRowLabels[table.tableKey] = {};
            currentColumnLabels[table.tableKey] = {};
            table.labels.forEach((label) => {
                currentRowLabels[table.tableKey][label.rowKey] = true;
                currentColumnLabels[table.tableKey][label.columnKey] = true;
            });
        });

        Object.keys(currentColumnLabels).forEach((table) => {
            Object.keys(currentColumnLabels[table]).forEach((columnKey) => {
                if (!updatedColumnLabels?.[table]?.[columnKey]) {
                    if (!(table in columnDocumentCountDifference)) {
                        columnDocumentCountDifference[table] = {};
                    }
                    columnDocumentCountDifference[table][columnKey] = -1;
                }
            });
        });

        Object.keys(updatedColumnLabels).forEach((table) => {
            Object.keys(updatedColumnLabels[table]).forEach((columnKey) => {
                if (!currentColumnLabels?.[table]?.[columnKey]) {
                    if (!(table in columnDocumentCountDifference)) {
                        columnDocumentCountDifference[table] = {};
                    }
                    columnDocumentCountDifference[table][columnKey] = 1;
                }
            });
        });

        Object.keys(currentRowLabels).forEach((table) => {
            Object.keys(currentRowLabels[table]).forEach((rowKey) => {
                if (!updatedRowLabels?.[table]?.[rowKey]) {
                    if (!(table in rowDocumentCountDifference)) {
                        rowDocumentCountDifference[table] = {};
                    }
                    rowDocumentCountDifference[table][rowKey] = -1;
                }
            });
        });

        Object.keys(updatedRowLabels).forEach((table) => {
            Object.keys(updatedRowLabels[table]).forEach((rowKey) => {
                if (!currentRowLabels?.[table]?.[rowKey]) {
                    if (!(table in rowDocumentCountDifference)) {
                        rowDocumentCountDifference[table] = {};
                    }
                    rowDocumentCountDifference[table][rowKey] = 1;
                }
            });
        });

        const assetDocumentCountDifference = {};
        const updatedAssetLabels = {};
        const currentAssetLabels = {};
        assetMetadata.labelData?.labels?.forEach((label) => {
            updatedAssetLabels[label.label] = true;
        });
        this.state.selectedAsset.labelData?.labels?.forEach((label) => {
            currentAssetLabels[label.label] = true;
        });
        Object.keys(currentAssetLabels).forEach((label) => {
            if (!updatedAssetLabels[label]) {
                assetDocumentCountDifference[label] = -1;
            }
        });
        Object.keys(updatedAssetLabels).forEach((label) => {
            if (!currentAssetLabels[label]) {
                assetDocumentCountDifference[label] = 1;
            }
        });
        // await this.props.actions.updatedAssetMetadata(
        //     this.props.project,
        //     assetDocumentCountDifference,
        //     columnDocumentCountDifference,
        //     rowDocumentCountDifference
        // );
    }

    /**
     * Raised when the asset binary has been painted onto the canvas tools rendering canvas
     */
    private onCanvasRendered = async (canvas: HTMLCanvasElement) => {
        // When active learning auto-detect is enabled
        // run predictions when asset changes
    };

    private onSelectedRegionsChanged = (selectedRegions: IRegion[]) => {
        console.log('onSelectedRegionsChanged');        
        if (this.state.isProcessingLabel) {
            return;
        }
        this.setState(prevState => {
            return {
                ...prevState,
                selectedRegions
            }
        });
    };

    private onRegionDoubleClick = (region: IRegion) => {
        console.log('onRegionDoubleClick');
        if (region.tags?.length > 0 && this.tagInputRef.current) {
            this.tagInputRef.current.focusTag(region.tags[0]);
        }
    };

    private onCanvasRunningOCRStatusChanged = (ocrStatus: OcrStatus) => {
        // if (
        //     ocrStatus === OcrStatus.done &&
        //     this.state.selectedAsset?.asset?.state === AssetState.NotVisited
        // ) {
        //     const allAssets: { [index: string]: IAsset } = _.cloneDeep(
        //         this.props.project.assets
        //     );
        //     const asset = Object.values(allAssets).find(
        //         (item) => item.id === this.state.selectedAsset?.asset?.id
        //     );
        //     if (asset) {
        //         asset.state = AssetState.Visited;
        //         Promise.all([
        //             this.props.actions.saveProject(
        //                 { ...this.props.project, assets: allAssets },
        //                 false,
        //                 false,
        //                 this.savedToken
        //             ),
        //         ]);
        //     }
        // }

        // this.setState({
        //     isCanvasRunningOCR: ocrStatus === OcrStatus.runningOCR,
        //     loadingFromBlob: ocrStatus === OcrStatus.loadingFromAzureBlob,
        // });
    };

    private onCanvasRunningAutoLabelingStatusChanged = (
        isCanvasRunningAutoLabeling: boolean
    ) => {
        //this.setState({ isCanvasRunningAutoLabeling });
    };

    private onTagChanged = async (oldTag: ITag, newTag: ITag) => {
        // const assetUpdates = await this.props.actions.updateProjectTag(
        //     this.props.project,
        //     oldTag,
        //     newTag
        // );
        // const selectedAsset = assetUpdates.find(
        //     (am) => am.asset.id === this.state.selectedAsset.asset.id
        // );

        // if (selectedAsset) {
        //     this.setState({
        //         selectedAsset,
        //     });
        // }
    };

    /**
     * Open Confirm dialog for document deletion
     */
    private confirmDocumentDeleted = (): void => {
        //this.deleteDocumentConfirm.current.open();
    };

    private setTableToView = async (tableToView, tableToViewId) => {
        // if (this.state.tableToViewId) {
        //     this.canvas.current.setTableState(this.state.tableToViewId, "rest");
        // }
        // this.canvas.current.setTableState(tableToViewId, "selected");
        // this.setState({
        //     tableToView,
        //     tableToViewId,
        // });
    };

    private closeTableView = (state: string) => {
        // if (this.state.tableToView) {
        //     this.canvas.current.setTableState(this.state.tableToViewId, state);
        //     this.setState({
        //         tableToView: null,
        //         tableToViewId: null,
        //     });
        // }
    };

    public loadOcrForNotVisited = async (runForAll?: boolean) => {
        // if (this.isBusy()) {
        //     return;
        // }
        // console.log('loadOcrForNotVisited');
        // const { project } = this.props;
        // const ocrService = new OCRService(project);
        // if (this.state.assets) {
        //     this.setState({ isRunningOCRs: true });
        //     try {
        //         this.isOCROrAutoLabelingBatchRunning = true;
        //         await throttle(
        //             constants.maxConcurrentServiceRequests,
        //             this.state.assets
        //                 .filter((asset) =>
        //                     runForAll
        //                         ? asset
        //                         : asset.state === AssetState.NotVisited
        //                 )
        //                 .map((asset) => asset.id),
        //             async (assetId) => {
        //                 // Get the latest version of asset.
        //                 const asset = this.state.assets.find(
        //                     (asset) => asset.id === assetId
        //                 );
        //                 if (
        //                     asset &&
        //                     (asset.state === AssetState.NotVisited || runForAll)
        //                 ) {
        //                     try {
        //                         this.updateAssetOCRAndAutoLabelingState({
        //                             id: asset.id,
        //                             isRunningOCR: true,
        //                         });
        //                         const ocrResult =
        //                             await ocrService.getRecognizedText(
        //                                 asset.path,
        //                                 asset.name,
        //                                 asset.mimeType,
        //                                 undefined,
        //                                 runForAll
        //                             );

        //                         if (ocrResult) {
        //                             this.updateAssetOCRAndAutoLabelingState({
        //                                 id: asset.id,
        //                                 isRunningOCR: false,
        //                             });
        //                             await this.props.actions.refreshAsset(
        //                                 this.props.project,
        //                                 asset.name
        //                             );
        //                         }
        //                     } catch (err) {
        //                         this.updateAssetOCRAndAutoLabelingState({
        //                             id: asset.id,
        //                             isRunningOCR: false,
        //                         });
        //                         this.setState({
        //                             isError: true,
        //                             errorTitle: err.title,
        //                             errorMessage: err.message,
        //                         });
        //                     }
        //                 }
        //             }
        //         );
        //     } finally {
        //         this.setState({ isRunningOCRs: false });
        //         this.isOCROrAutoLabelingBatchRunning = false;
        //     }
        // }
    };

    private reconfigureTableConfirm = (
        originalTagName: string,
        tagName: string,
        tagType: FieldType.Array | FieldType.Object,
        tagFormat: FieldFormat,
        visualizationHint: TableVisualizationHint,
        deletedColumns: ITableConfigItem[],
        deletedRows: ITableConfigItem[],
        newRows: ITableConfigItem[],
        newColumns: ITableConfigItem[]
    ) => {
        this.setState({ reconfigureTableConfirm: true });
        this.reconfigTableConfirm.current.open(
            originalTagName,
            tagName,
            tagType,
            tagFormat,
            visualizationHint,
            deletedColumns,
            deletedRows,
            newRows,
            newColumns
        );
    };

    private onTagDeleteChecked() {
        console.log("deleted");
    }

    private onPageLoaded = async (pageNumber: number) => {
        this.setState(prevState => {
            return {
                ...prevState,
                pageNumber
            }
        });
    };

    private runAutoLabelingOnNextBatch = async (batchSize: number) => {
        // if (this.isBusy()) {
        //     return;
        // }
        // const { project } = this.props;
        // const predictService = new PredictService(project);
        // const assetService = new AssetService(project);

        // if (this.state.assets) {
        //     this.setState({ isRunningAutoLabelings: true });
        //     const unlabeledAssetsBatch = [];
        //     for (
        //         let i = 0;
        //         i < this.state.assets.length &&
        //         unlabeledAssetsBatch.length < batchSize;
        //         i++
        //     ) {
        //         const asset = this.state.assets[i];
        //         if (
        //             asset.state === AssetState.NotVisited ||
        //             asset.state === AssetState.Visited
        //         ) {
        //             unlabeledAssetsBatch.push(asset);
        //         }
        //     }
        //     const allAssets = _.cloneDeep(this.props.project.assets);
        //     try {
        //         this.isOCROrAutoLabelingBatchRunning = true;
        //         await throttle(
        //             constants.maxConcurrentServiceRequests,
        //             unlabeledAssetsBatch,
        //             async (asset) => {
        //                 try {
        //                     this.updateAssetOCRAndAutoLabelingState({
        //                         id: asset.id,
        //                         isRunningAutoLabeling: true,
        //                     });
        //                     const predictResult =
        //                         await predictService.getPrediction(asset.path);
        //                     const assetMetadata =
        //                         await assetService.getAssetPredictMetadata(
        //                             asset,
        //                             predictResult
        //                         );
        //                     await assetService.uploadPredictResultAsOrcResult(
        //                         asset,
        //                         predictResult
        //                     );
        //                     assetMetadata.asset.isRunningAutoLabeling = false;
        //                     await this.onAssetMetadataChanged(assetMetadata);
        //                     allAssets[asset.id] = assetMetadata.asset;
        //                     await this.props.actions.updatedAssetMetadata(
        //                         this.props.project,
        //                         assetMetadata
        //                     );
        //                 } catch (err) {
        //                     this.updateAssetOCRAndAutoLabelingState({
        //                         id: asset.id,
        //                         isRunningOCR: false,
        //                         isRunningAutoLabeling: false,
        //                     });
        //                     this.setState({
        //                         isError: true,
        //                         errorTitle: err.title,
        //                         errorMessage: err.message,
        //                     });
        //                 }
        //             }
        //         );
        //     } finally {
        //         await this.props.actions.saveProject(
        //             { ...this.props.project, assets: allAssets },
        //             true,
        //             false,
        //             this.savedToken
        //         );
        //         this.setState({ isRunningAutoLabelings: false });
        //         this.isOCROrAutoLabelingBatchRunning = false;
        //     }
        // }
    };

    private handleLabelTable = (
        tagInputMode: TagInputMode = this.state.tagInputMode,
        selectedTableTagToLabel: ITableTag = this.state.selectedTableTagToLabel
    ) => {
        // if (selectedTableTagToLabel == null || !this.state.selectedAsset) {
        //     return;
        // }

        // let rowKeys;
        // let columnKeys;
        // if (selectedTableTagToLabel.type === FieldType.Object) {
        //     if (
        //         selectedTableTagToLabel.visualizationHint ===
        //         TableVisualizationHint.Vertical
        //     ) {
        //         columnKeys = selectedTableTagToLabel.definition.fields;
        //         rowKeys = selectedTableTagToLabel.fields;
        //     } else {
        //         columnKeys = selectedTableTagToLabel.fields;
        //         rowKeys = selectedTableTagToLabel.definition.fields;
        //     }
        // } else {
        //     rowKeys = null;
        //     columnKeys = selectedTableTagToLabel.definition.fields;
        // }

        // const selectedTableTagBody = new Array(rowKeys?.length || 1);
        // if (
        //     this.state.selectedTableTagToLabel?.name ===
        //         selectedTableTagToLabel?.name &&
        //     selectedTableTagToLabel.type === FieldType.Array
        // ) {
        //     for (let i = 1; i < this.state.selectedTableTagBody.length; i++) {
        //         selectedTableTagBody.push(undefined);
        //     }
        // }
        // for (let i = 0; i < selectedTableTagBody.length; i++) {
        //     selectedTableTagBody[i] = new Array(columnKeys.length);
        // }

        // const tagAssets = clone()(this.state.selectedAsset.regions).filter(
        //     (region) => region.tags[0] === selectedTableTagToLabel.name
        // ) as ITableRegion[];
        // tagAssets.forEach((region) => {
        //     let rowIndex: number;
        //     if (selectedTableTagToLabel.type === FieldType.Array) {
        //         rowIndex = Number(region.rowKey.slice(1));
        //     } else {
        //         rowIndex = rowKeys.findIndex(
        //             (rowKey) => rowKey.fieldKey === region.rowKey
        //         );
        //     }
        //     for (let i = selectedTableTagBody.length; i <= rowIndex; i++) {
        //         selectedTableTagBody.push(new Array(columnKeys.length));
        //     }
        //     const colIndex = columnKeys.findIndex(
        //         (colKey) => colKey.fieldKey === region.columnKey
        //     );
        //     if (selectedTableTagBody[rowIndex][colIndex] != null) {
        //         selectedTableTagBody[rowIndex][colIndex].push(region);
        //     } else {
        //         selectedTableTagBody[rowIndex][colIndex] = [region];
        //     }
        // });

        // this.setState((prevState) => {
        //     return {
        //         ...prevState,
        //         selectedTableTagToLabel,
        //         selectedTableTagBody
        //     }
        // },
        //     () => {
        //         this.setTagInputMode(tagInputMode);
        //     });
    };

    private onHandleSearch = () => {
        // if (!this.canvas.current.areAllPagesOCR()) {
        //     this.confirmOCRSearchAll.current.open();
        // } else {
        // }
        this.setState((prevState) => {
            return {
                ...prevState,
                showSearch: true
            }
        });
    };

    private showUploadGeneratedTagsToApi() {
        this.setState((prevState) => {
            return {
                ...prevState,
                showUploadTagsModal: true,
                uploadTagsModalKey: new Date().getTime()
            }
        });
    }

    private onBeforeAssetSelected = (): boolean => {
        if (!this.state.isValid) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    showInvalidRegionWarning: true
                }
            });
        }

        return this.state.isValid;
    };

    private selectAsset = async (
        asset: IAsset,
        force: boolean = false
    ): Promise<void> => {
        // Nothing to do if we are already on the same asset.
        console.log('selectAsset');
        if (
            !force &&
            this.state.selectedAsset &&
            this.state.selectedAsset.asset.id === asset.id
        ) {
            return;
        }

        if (!this.state.isValid) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    showInvalidRegionWarning: true
                }
            });
            return;
        }
        

        const self = this;

        this.setState(prevState => {
            return {
                ...prevState,
                processingSelectedAsset: true
            }
        }, async () => {
            
            const assetMetadata = await self.props.actions.loadAssetMetadataAdhoc(
                self.props.adHocOcr.project,
                asset
            );

            // Get regions from label data since meta data will not have regions when loaded.
            assetMetadata.regions =
                this.canvas.current?.convertLabelDataToRegions(
                    assetMetadata.labelData
                ) || [];

            self.setState(prevState => {
                return {
                    ...prevState,
                    tableToView: null,
                    tableToViewId: null,
                    selectedAsset: assetMetadata,
                    processingSelectedAsset: false
                }
            }
                // async () => {
                //     setTimeout(async () => {
                //         await self.onAssetMetadataChanged(assetMetadata);
                //         await this.props.actions.saveProject(
                //             self.props.project,
                //             false,
                //             false,
                //             this.savedToken
                //         );
                //         self.setState({
                //             processingSelectedAsset: false
                //         });
                //     }, 300);
                // }
            )
        })
    };

    private loadProjectAssets = async (
        force: boolean = false
    ): Promise<void> => {
        // if (this.loadingProjectAssets) {
        //     return;
        // }

        // this.loadingProjectAssets = true;
        const replacer = (key, value) => {
            if (key === "cachedImage") {
                return undefined;
            } else {
                return value;
            }
        };

        try {
            const keys = Object.keys(this.props.adHocOcr.project.assets);
            let assets = keys.map(key => this.props.adHocOcr.project.assets[key]);

            // if (
            //     this.state.assets.length === assets.length &&
            //     JSON.stringify(this.state.assets, replacer) ===
            //     JSON.stringify(assets)
            // ) {
            //     this.loadingProjectAssets = false;
            //     this.setState({ tagsLoaded: true });
            //     return;
            // }

            // const lastVisited = assets.find(
            //     (asset) => asset.id === this.props.project.lastVisitedAssetId
            // );

            //this.assetsCleanState = JSON.stringify(assets);

            //this.setState((state) => {
            //    return {
            //        ...state,
            //        assets: []
            //    }
            //}, () => {

            //});
            this.setState(
                {
                    assets: assets
                },
                async () => {
                    if (assets.length > 0) {
                        await this.selectAsset(assets[0]);
                    }
                    // await this.props.actions.saveProject(
                    //     this.props.project,
                    //     false,
                    //     true,
                    //     this.savedToken
                    // );
                    // this.setState({ tagsLoaded: true });
                    
                    // this.loadingProjectAssets = false;
                }
            );
        } catch (error) {
            throw Error(error);
        }
        finally {
            //this.loadingProjectAssets = false;
        }
    };
}
